let initialState = {
    categories: []
}

const categoriesReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'CATEGORIES':
            return {
                ...state,
                categories: action.payload
            }
        default: return state
    }
}

export default categoriesReducer