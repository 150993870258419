import React, { useEffect, useState } from "react";
import {NavLink, Link} from 'react-router-dom';
import {Container, Dropdown, Nav, Navbar} from 'react-bootstrap';
import 'bootstrap/scss/bootstrap.scss';
import { ToastContainer } from 'react-toastify';
import styled from "styled-components";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { GET } from '../../utils/axios.util';

import '../common/_Header_footer.scss';
import AddBlogModalCtrl from '../modal/AddBlogModal';
import SharePostModalCtrl from '../modal/SharePostModal';
import SubscribeModalCtrl from '../modal/SubscribeModal';
import HeaderSearchCtrl from '../common/head_search';
function HeaderCtrl(props){
  const [isActive, setActive] = useState(false);
  
  // const toggleClass = () => {
  //   setActive(!isActive);
  // };

  const [ pages, setPages ] = useState([])
  const [addBlogModalShow, setAddBlogModalShow] = React.useState(false);
  const [sharePostModalShow, setSharePostModalShow] = React.useState(false);
  const [subscribeModalShow, setSubscribeModalShow] = React.useState(false);

  const [ categories, setCategories ] = useState([])
  const [ options, setOptions ] = useState(null)

  const [ state, setState ] = useState({ categories, options })

  var textColor = {color: props.website_color}

  const CustomSpan = styled.span`
    background: ${props.website_color} !important;
  `

  useEffect(() => {

    if(!(categories.length > 0)) {
        getCategories()
    }
    if(categories.length > 0 && !options) {
        let optionValues =  categories.map((e)=>{
            return (
                <option key={e.id} value={e.id}>{e.name}</option>
            )
        }) 
        setOptions(optionValues)
        setState({
          ...state,
          options: optionValues
        })
    }
    if(!(pages.length > 0)) {
      getPages()
    }

  },[ pages, categories, options, state ]);

  const getCategories = () => {
    let payload = {
        url : `${process.env.REACT_APP_SERVER}/list-categories`
    }

    GET(payload).then(res => {
        if(res.data) { 
            const { data } = res.data
            setCategories(data)
            setState({
              ...state,
              categories: data
            })
        }
    }).catch(err => {
        console.log(err)
    })
  }

  const getPages = () => {

    let payload = {
      url : `${process.env.REACT_APP_SERVER}/static-pages`
    }
    GET(payload).then(res => {
      if(res.data) { 
        const { data } = res.data
        setPages(data)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  return(
      <>
        <Navbar sticky="top" className="top_header" bg="white" expand="lg">
          <Container className="justify-content-lg-center">
              <a className="navbar-brand" href="/">
                <img src={props.logo} />
              </a>
            <div className="d-flex align-items-center">
              <Dropdown className="d-inline mx-2 position-static  d-block d-lg-none" style={{position : "iniitial"}} id="searchDIv" >
                  <Dropdown.Toggle className="nav-link search_link search_toggle_btn ms-auto" id="searchButton" >
                    <FontAwesomeIcon icon={icon({name: 'magnifying-glass', style: 'solid'})} className=""  style={{fontSize: '20px', color: props.website_color, verticalAlign: 'middle'}} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="search_bar">
                    <div>
                      <HeaderSearchCtrl {...props} />
                    </div>
                  </Dropdown.Menu>
              </Dropdown>
              <Navbar.Toggle aria-controls="navbarScroll" id="menuButton" >
                <div className="toggle_menu_btn">
                  <CustomSpan className="line-toggle"></CustomSpan>
                  <CustomSpan className="line-toggle"></CustomSpan>
                  <CustomSpan className="line-toggle"></CustomSpan>
                </div>
              </Navbar.Toggle>
            </div>

            <Navbar.Collapse id="navbarScroll" style={{ flexGrow: 'inherit' }} >
              <Nav className="align-items-center" navbarScroll>
                <Link className="nav-link" onClick={() => setAddBlogModalShow(true)} style={textColor} >Add Your Blog</Link>
                <Link className="nav-link" onClick={() => setSharePostModalShow(true)} style={textColor} >Share a Post</Link>

                { pages.length > 0 &&
                    pages.map((elem, index) => {
                      return (
                        <div key={elem.id}>
                          <NavLink className="nav-link" to={"/"+elem.slug} style={({ isActive }) => ({
                                color: isActive ? '#ffffff' : props.website_color,
                                backgroundColor: isActive && props.website_color,
                              })} >{elem.title}</NavLink>
                        </div>
                      )
                    })
                }
                <Link className="nav-link" to onClick={() => setSubscribeModalShow (true)} style={textColor} >Subscribe</Link>
                {/* <span className="nav-link search_link"  onClick={toggleClass}>
                  <img src={search} />
                </span> */}
              </Nav>
              <Dropdown className="d-inline mx-2 position-static d-none d-lg-block" style={{position : "iniitial"}}>
                <Dropdown.Toggle className="nav-link search_link ms-auto">
                  <FontAwesomeIcon icon={icon({name: 'magnifying-glass', style: 'solid'})} className=""  style={{fontSize: '20px', color: props.website_color, verticalAlign: 'middle'}} />
                </Dropdown.Toggle>

                <Dropdown.Menu className="search_bar">
                  <div>
                    <HeaderSearchCtrl {...props} />
                  </div>
                </Dropdown.Menu>
              </Dropdown>

            </Navbar.Collapse>
            {/* <div className={isActive ? "search_bar_active" : 'search_bar'} >
              <HeaderSearchCtrl />
            </div> */}
          </Container>
        </Navbar>

        <AddBlogModalCtrl state={state} show={addBlogModalShow} onHide={() => setAddBlogModalShow(false)} {...props} />
        <SharePostModalCtrl state={state} show={sharePostModalShow} onHide={() => setSharePostModalShow(false)} {...props} /> 
        <SubscribeModalCtrl show={subscribeModalShow} onHide={() => setSubscribeModalShow(false)} {...props} /> 

        <ToastContainer />
      </>
  )
}


export default HeaderCtrl;