import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link, Outlet } from "react-router-dom";
import SideMenuBarCtrl from '../common/Sidebar';
import 'bootstrap/scss/bootstrap.scss';
import { ToastContainer } from 'react-toastify';

function DashboardLayout(props){
    const user = JSON.parse(localStorage.getItem('user'));
    let navigate = useNavigate();

    useEffect( () => {
        if(!user) {
           navigate('/admin/settings')
        }
    }, []) 
    
    if(user) {
        return(
            <>
                <section className="user_body">
                    <SideMenuBarCtrl {...props} />
                    <section className="user_conatiner_body">
                        <Outlet/>
                        <ToastContainer />
                    </section>
                </section>
            </>
        )
    }else {
        return navigate('/admin/login')
    }
}
export default DashboardLayout;