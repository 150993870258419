import React, { useEffect, useState } from "react";
import {Button, Col,Dropdown, InputGroup, Form, Row  } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { GET, POST } from '../../../utils/axios.util';
import Loader from '../../common/loader';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SettingsPageCtrl(props){

  const { register, handleSubmit, setError, setValue, formState: { errors } } = useForm();

  const [ currentSettings, setCurrentSettings ] = useState('')
  const [ file, setFile ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isNoRecords, setIsNoRecords ] = useState(false)
  const [ previewImage, setPreviewImage] = useState('');
  const [ isDisabled, setIsDisabled ] = useState('')

  useEffect(() => {
    if(Object.keys(currentSettings).length === 0) {
      getCurrentSettings()
    }
    
  },[currentSettings]);
  
  const getCurrentSettings = () => {
      
    setIsLoading(true)
    let payload = {
      url : `${process.env.REACT_APP_SERVER}/admin/settings`
    }
    GET(payload).then(res => {
        if(res.data) { 
          const { data } = res.data

          setIsLoading(false)
          setIsNoRecords(false)
          setPreviewImage(data.logo)
          setCurrentSettings(data)
        }
    }).catch(err => {
      setIsLoading(false)
      if(err.response.status === 404) {
        setIsNoRecords(true)
      }
    })

  }

  const handleUploadChange = (event) => {

    if(event.target.files[0] &&  event.target.files[0] !== undefined && event.target.files[0] !== null){

      setFile(event.target.files[0])
      setValue('logo', event.target.files[0]['name'])
      setPreviewImage(URL.createObjectURL(event.target.files[0]));
    }
  }

  const websiteColorChange = (event) => {
    setValue('website_color', event.target.value)
    setValue('website_color1', event.target.value)
  }

  const highlightColorChange = (event) => {
    setValue('highlight_color', event.target.value)
    setValue('highlight_color1', event.target.value)
  }

  const submitForm = (data) => {
    const formData = new FormData();
    formData.append("logo", file);
    formData.append("setting_id", currentSettings.id);
    formData.append("website_color", data.website_color);
    formData.append("font_family", data.font_family);
    formData.append("template_style", data.template_style);
    formData.append("highlight_color", data.highlight_color);

    let payload = {
      url : `${process.env.REACT_APP_SERVER}/admin/settings/edit`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    }

    setIsDisabled(true)
    POST(payload).then(res => {
      toast.success(res.data.message)
      window.location.reload(false);

    }).catch(err => {
        if(err) {
            setIsDisabled('')
            console.log(err)
        }
    })
  }

  return(
      <>
      <section className="admin_page_body">
        <div className="text-center">
          <h1 className="ad_page_hdng" style={{color: props.website_color}} >Settings</h1>
        </div>
        { isLoading && 
          <Loader />
        } 
        { (isNoRecords == false) && !isLoading && Object.keys(currentSettings).length > 0 &&
          <Form encType="multipart/form-data" onSubmit={handleSubmit(submitForm)}>
            <div>
              <Button variant="danger" size="md" className="m-2" type="submit" style={{backgroundColor: props.website_color}} disabled={isDisabled} >UPDATE</Button>
            </div>
            
            <input id="upload" name="upload" type="file" onChange={handleUploadChange}  hidden />

            <Row>
              <Col sm="3">
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>PRIMARY WEBSITE COLOR</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control defaultValue={currentSettings.website_color} name="website_color" {...register("website_color", { required: true })} onChange={websiteColorChange} />
                      <input className="color_picker" type="color" id="" defaultValue={currentSettings.website_color} onInput={websiteColorChange} {...register("website_color1", { required: true })} />
                    </InputGroup>
                  </Form.Group>
                  {errors.website_color ?.message && <p className="error">{errors.website_color ?.message}</p> }
              </Col>              
            </Row>
            <Row>
              <Col sm="3">
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>WEBSITE LOGO</Form.Label>
                    <Form.Control type="text" placeholder="[upload file here]" name="logo" defaultValue={currentSettings.logo_originalname} onClick={() => document.getElementById("upload").click()} {...register("logo", { required: true })} />
                    {errors.logo ?.message && <p className="error">{errors.logo ?.message}</p> }
                  </Form.Group>
                </Col>
            </Row>
            { previewImage &&
              <Row>
                <Col sm="3">
                  <img width="100"src={previewImage} />
                </Col>
              </Row>
            }
            <Row>
              <Col sm="3">
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>WEBSITE FONT FAMILY</Form.Label>
                    <Form.Select className="text-center" name="font_family" defaultValue={currentSettings.font_family} {...register("font_family", { required: true })} >
                      <option value="Poppins">Poppins</option>
                      <option value="sans-serif">sans-serif</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
            </Row>
            <Row>
              <Col sm="3">
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>HOMEPAGE TEMPLATE STYLE</Form.Label>
                    <Form.Select className="text-center" name="template_style" defaultValue={currentSettings.template_style} {...register("template_style", { required: true })} >
                      <option value="alternate">Alternate</option>
                      <option value="primary">Primary</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
            </Row>
            <Row>
              <Col sm="3">
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>HIGHLIGHT COLOR</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control defaultValue={currentSettings.highlight_color} name="highlight_color" {...register("highlight_color", { required: true })} onChange={highlightColorChange} />
                      <input className="color_picker" type="color" id="" defaultValue={currentSettings.highlight_color} onInput={highlightColorChange} {...register("highlight_color1", { required: true })} />
                    </InputGroup>
                    {errors.highlight_color ?.message && <p className="error">{errors.highlight_color ?.message}</p> }
                  </Form.Group>
              </Col> 
            </Row>
            {/* <Row>
              <Col sm="12">
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>CUSTOM HEADER CONTENT</Form.Label>
                    <div className="settings_pre_code">
                      <pre>
                        &lt;script&gt; 
                        <br/>
                          $&#10088;document&#10089;.on&#10088;'ready', function&#10088;&#10100; <br/>
                          alert&#10088;'Custom code can go here&#10089;;<br/>
                            &#10101;&#10089;&#10089;
                        <br/>
                        &lt;script /&gt;
                      </pre>
                    </div>
                  </Form.Group>
              </Col> 
            </Row> */}
          </Form>
        }
        { isNoRecords && 
          <p className="text-center">
            No Records Found!
          </p>
        }
      </section>
      </>
  )
}

export default SettingsPageCtrl;