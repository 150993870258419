import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";  
import store from "./redux/store.js";
// import App from './App';

import { BrowserRouter } from "react-router-dom";

import Root from '../src/routes/Routes.dev';

import './assets/css/_Style.scss';
import './assets/css/_Font.scss';
import './_Index.scss';
import './assets/css/_Responsive.scss';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Root />
    </Provider>
  </BrowserRouter>,
);



