import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Table, Col, Row } from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import { useForm } from "react-hook-form";
import moment from 'moment-timezone';

import { GET, PATCH, POST } from '../../../utils/axios.util';
import Loader from '../../common/loader';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FeedsPageCtrl = (props) => {
  
  const { register, handleSubmit, setValue, setError, formState: { errors } } = useForm();
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm()
  const [ feeds, setFeeds ] = useState([])
  const [ categories, setCategories ] = useState([])
  const [ options, setOptions ] = useState(null)
  const [ feedModal, setFeedModal ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isNoRecords, setIsNoRecords ] = useState(false)
  const [ modalFeedId, setModalFeedId ] = useState('')
  const [ searchKeyword, setSearchKeyword ] = useState('')

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 25
  const [ currentItems, setCurrentItems ] = useState([])
  const [ pageCount, setPageCount ] = useState(0);

  useEffect(() => {

    if(!(feeds.length > 0)) {
      getFeeds()
    }
    if(!(categories.length > 0)) {
      getCategories()
    }
    if(categories.length > 0 && !options) {
      let optionValues =  categories.map((e)=>{
          return (
            <option key={e.id} value={e.id}>{e.name}</option>
          )
      }) 
      setOptions(optionValues)
    }

  },[currentItems, categories, options, itemOffset, itemsPerPage]);

  const getFeeds = () => {
    
    setIsLoading(true)
    let payload = {
        url : `${process.env.REACT_APP_SERVER}/admin/feeds?search=${searchKeyword}`
    }
    GET(payload).then(res => {
        if(res.data) { 
            const { data } = res.data
            const pageCount = Math.ceil(data.length / itemsPerPage);
            const endOffset = itemOffset + itemsPerPage;
            let chunk = data.slice(itemOffset, endOffset)
            setIsNoRecords(false)
            setIsLoading(false)
            setFeeds(data)
            setPageCount(pageCount)
            setCurrentItems(chunk)
        }
    }).catch(err => {
      setIsLoading(false)
      if(err.response.status === 404) {
        setIsNoRecords(true)
      }
    })
  }

  const getCategories = () => {
    let payload = {
        url : `${process.env.REACT_APP_SERVER}/list-categories`
    }

    GET(payload).then(res => {
        if(res.data) { 
            const { data } = res.data
            setCategories(data)
        }
    }).catch(err => {
        console.log(err)
    })
  }

  const setupModal = (event) => {
    let feedId = event.currentTarget.getAttribute('data-id')
    
    let filterFeed = feeds.filter(fd => {
      return fd.id == feedId
    })

    setValue('business_name', filterFeed[0]['business_name']);
    setValue('rss_url', filterFeed[0]['rss_url']);
    setValue('twitter', filterFeed[0]['twitter_handle']);
    setValue('category', filterFeed[0]['category_id']);

    setModalFeedId(feedId)
    setFeedModal(true)
  }

  const onSubmit = (data) => {
    data['feed_id'] = modalFeedId
    let payload = {
      url : `${process.env.REACT_APP_SERVER}/admin/feeds/edit`,
      data
    }
    
    PATCH(payload).then(res => {
      toast.success(res.data.message)
      getFeeds()
      setFeedModal(false)

    }).catch(err => {
        if(err) {
          const { data } = err.response.data
          let serverErrors = data
          serverErrors.forEach(errItem => {
              setError(errItem.field, {
                  type: "server",
                  message: errItem.message,
              });
          });
        }
    })
  }

  const deleteFeed = (fdId) => {

    var feedDelData =  {
      feed_id: fdId
    }

    let payload = {
      url : `${process.env.REACT_APP_SERVER}/admin/feeds/delete`,
      data: feedDelData
    }

    POST(payload).then(res => {
      toast.success(res.data.message)
      getFeeds()

    }).catch(err => {
      if(err.response.status === 403) {
        toast.error(err.response.data.message)
      }
    })

  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % feeds.length;
    
    const endOffset = newOffset + itemsPerPage;
    let chunk = feeds.slice(newOffset, endOffset)
    const pageCount = Math.ceil(feeds.length / itemsPerPage);
    setPageCount(pageCount)
    setCurrentItems(chunk)
    setItemOffset(newOffset);
  };

  const onSearchSubmit = (data) => {
    getFeeds()
  }

    return(
      <>
        <section className="admin_page_body">
          <div className="text-center">
            <h1 className="ad_page_hdng" style={{color: props.website_color}} >Feeds</h1>
          </div>
          <Form className="mt-3" onSubmit={handleSubmit2(onSearchSubmit)} >
            <Row>
              <Col sm={10}>
                <Form.Group controlId="">
                  <Form.Control type="text" placeholder="Enter search query here" defaultValue={''} name="keyword" {...register2("keyword")} onChange={(event) => {setSearchKeyword(event.target.value); setValue('keyword', event.target.value)} } />
                </Form.Group>
              </Col>
              <Col sm={2}>
                <Form.Group controlId="">
                  <Button variant="danger" size="md" type="submit" style={{backgroundColor: props.website_color}} >SEARCH</Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <div className="m-2">   
            { isLoading && 
              <Loader />
            }   
            <Table responsive>
              <thead>
                <tr>
                  <th  width="150">Name</th>
                  <th width="200">RSS URL</th>
                  <th>Category</th>
                  <th>Twitter</th>
                  <th>Created</th>
                  <th>Approved</th>
                  <th colSpan={2}>Posts</th>
                </tr>
              </thead>
              <tbody>
                { (isNoRecords == false) && !isLoading && currentItems.length > 0 &&

                  currentItems.map((elem, index) => {
                    let categoryFilter = categories.filter(catgy => {
                      return catgy.id === elem.category_id
                    })
                    return (
                      <tr key={elem.id}>
                      <td width="150">{elem.business_name}</td>
                      <td width="200">{elem.rss_url}</td>
                      <td>{elem.category_name}</td>
                      <td>{elem.twitter_handle}</td>
                      <td>
                        {elem.created_at && moment(elem.created_at).tz(process.env.REACT_APP_TIME_ZONE).format('YYYY-MM-DD')} 
                        <br/> 
                        {elem.created_at && moment(elem.created_at).tz(process.env.REACT_APP_TIME_ZONE).format('HH:mm:ss')}
                      </td>
                      <td>
                        {elem.approved_on_dts && moment(elem.approved_on_dts).tz(process.env.REACT_APP_TIME_ZONE).format('YYYY-MM-DD')} 
                        <br/> 
                        {elem.approved_on_dts && moment(elem.approved_on_dts).tz(process.env.REACT_APP_TIME_ZONE).format('HH:mm:ss')}
                      </td>
                      <td>
                        {elem.posts_count}
                      </td>
                      <td width={100}>
                        <div className="d-grid gap-2">
                          <Button variant="danger" size="sm" data-id={elem.id} onClick={setupModal} style={{backgroundColor: props.website_color}} >EDIT</Button>
                          <Button variant="danger" size="sm" onClick={() => deleteFeed(elem.id)} style={{backgroundColor: props.website_color}} >DELETE</Button>
                        </div>
                      </td>
                    </tr>
                    )
                  })
                }
                { isNoRecords && 
                  <tr className="text-center">
                    <td colSpan="6" className="fs-1">
                      No Records Found!
                    </td>
                  </tr>
                }
              </tbody>
            </Table>
            
            { (isNoRecords == false) && !isLoading && currentItems.length > 0 &&
                <ReactPaginate 
                  className="justify-content-center mt-4 pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  activeClassName="active"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  breakLabel="..."
                  nextLabel={currentItems[currentItems.length-1]['id'] === feeds[feeds.length-1]['id'] ? null : 'Next'}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel={currentItems[0]['id'] === feeds[0]['id'] ? null : 'Previous'}
                  renderOnZeroPageCount={null}
                />
            }
            {/* <Pagination className="justify-content-center mt-4">
              <Pagination.Item>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Item>{3}</Pagination.Item>
              <Pagination.Item active>{4}</Pagination.Item>
              <Pagination.Item>{5}</Pagination.Item>
              <Pagination.Ellipsis />
              <Pagination.Item>Next</Pagination.Item>
              <Pagination.Item>Last</Pagination.Item>
            </Pagination> */}
          </div>
        </section>

        <Modal aria-labelledby="Edit Feed" centered keyboard={false} show={feedModal} onHide={() => setFeedModal(false)} style={{ fontFamily: props.font_family }} >
          <Modal.Header>
            <Modal.Title className='text_soft_red mx-auto' style={{color: props.website_color}} >Edit</Modal.Title>
          </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit(onSubmit)} >
                  <Form.Group className="mb-3" controlId="">
                      <Form.Label>Business Name</Form.Label>
                      <Form.Control type="text" placeholder="Name" defaultValue={''} {...register("business_name", { required: true })} onChange={(event) => setValue('business_name', event.target.value)} name="business_name" />

                      {errors.business_name?.type === 'required' && <p className="error">Business name is required</p>}
                      {errors.business_name?.type === 'server' && <p className="error">{errors.business_name.message}</p>}

                  </Form.Group>

                  <Form.Group className="mb-3" controlId="">
                      <Form.Label>RSS FEED URL</Form.Label>
                      <Form.Control type="text" placeholder="" defaultValue={''} {...register("rss_url", {
                          pattern: {
                              value: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig,
                              message: "Invalid RSS URL"
                          }
                      })} onChange={(event) => setValue('rss_url', event.target.value)} name="rss_url" />

                      {errors.rss_url && <p className="error">{errors.rss_url.message}</p> }

                  </Form.Group>

                  <Form.Group className="mb-3" controlId="">
                      <Form.Label>TWITTER HANDLE (NO @ SYMBOL)</Form.Label>
                      <Form.Control type="text" placeholder="" defaultValue={''} {...register("twitter", {
                          pattern: {
                              value: /^[a-zA-Z0-9~!#$%^&*()_+=[\]{}|\\,.?: -]*$/,
                              message: "Invalid twitter handle"
                          }
                      })} onChange={(event) => setValue('twitter', event.target.value)} name="twitter" />

                      {errors.twitter ?.message && <p className="error">{errors.twitter ?.message}</p> }

                  </Form.Group>

                  <Form.Group className="mb-3" controlId="">
                      <Form.Label>CATEGORY</Form.Label>
                      <Form.Select id="" defaultValue={""} onChange={(event) => setValue('category', event.target.value)} {...register("category", { required: true })} name="category" >
                          <option value="">-- Choose a Category --</option>
                          {options}
                      </Form.Select>

                      {errors.category?.type === 'required' && <p className="error">Category is required</p>}
                      
                  </Form.Group>
                  <div className='d-grid'>
                      <Button variant="danger" size="lg" type="submit" style={{backgroundColor: props.website_color}} >SUBMIT</Button>
                  </div>
              </Form>
            </Modal.Body>
        </Modal>
      </>
    )
}

export default FeedsPageCtrl;