import React, { Fragment, useEffect, useState } from 'react';
import { Container,Row ,Col, Button
} from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { connect, useDispatch } from 'react-redux';

import { search } from "../../../redux/actions";

import { GET } from '../../../utils/axios.util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Loader from '../../common/loader';

import edit from '../../../assets/images/icons/edit.png';
import calender from '../../../assets/images/icons/calender.png';

const GridView = (props) => {
  const [ mapCategories, setMapCategories ] = useState({})
  const [ allCategories, setAllCategories ] = useState([])
  const [ moreCategories, setMoreCategories ] = useState({})
  const [ isLoading, setIsLoading ] = useState(false)
  const [ offset, setOffset ] = useState(0)
  const [ isNoRecords, setIsNoRecords ] = useState(false)
  const [ searchKeyword, setSearchKeyword ] = useState('')
  const [ searchCategory, setSearchCategory ] = useState('')
  const [ searchAuthor, setSearchAuthor ] = useState('')
  const [ promotions, setPromotions ] = useState(props.promotions)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  var textColor = {color: props.website_color}
  var promotionIndex = 0

  useEffect(() => {
    if((Object.keys(mapCategories).length === 0) || !(searchKeyword === props.keyword) || !(searchCategory === props.category) || !(searchAuthor === props.author) ) {
      setSearchKeyword(() => {
        return props.keyword
      })
      setSearchCategory(() => {
        return props.category
      })
      setSearchAuthor(() => {
        return props.author
      })

      getPosts()
    }

    setPromotions(props.promotions)

  }, [mapCategories, isNoRecords, props.promotions, props.keyword, props.category, props.author])

  const getPosts = () => {

    setIsLoading(true)
    let payload = {
      url : `${process.env.REACT_APP_SERVER}/grid-posts?&keyword=${props.keyword}&category=${props.category}&author=${props.author}`
    }
    GET(payload).then(res => {
        if(res.data) { 
            const { data } = res.data

            if(Object.keys(data).length > 0) {

              let categoriesGroup = {}
              let moreCategoriesGroup = {}
              for(let y in data) {
                let filteredPosts = []
                if(data[y].length >= offset) {

                  if(data[y].length > 5) {
                    
                    moreCategoriesGroup[y] = { 
                      id: data[y][0]['category_id'],
                      is_more: true, 
                      is_less: false, 
                      offset, 
                    }
                  }
                  filteredPosts = data[y].slice(offset, 5)
                }else {
        
                  filteredPosts = data[y]
                }
                categoriesGroup[y] = filteredPosts
                
              }

              setIsNoRecords(false)
              setIsLoading(false)
              setAllCategories(data)
              setMoreCategories(moreCategoriesGroup)
              setMapCategories(categoriesGroup)

            } 
        }
    }).catch(err => {
        setIsLoading(false)
        if(err.response.status === 404) {
          setIsNoRecords(true)
        }
    })
  }

  const viewMoreLess = (category_id) => {
    dispatch(search(props.keyword, category_id, props.author))
    props.onToggleList()
  }

  const viewAuthor = (author) => {
    dispatch(search(props.keyword, props.category, author))
    props.onToggleList()
  }
  
  return(
    <>
      <Container fluid="sm" className='grid_view_blogList' style={{ display: !props.show ? 'none' : 'block' }} >
        <Row>
          { (isNoRecords == false) && !isLoading && (Object.keys(mapCategories).length > 0) &&
              Object.keys(mapCategories).map((elem, index) => {

                var highlightColor = {}
                let num = index + 1

                let blogListWrap = 'blog_list'
                if(mapCategories[elem][0]['is_highlighted'] === 1) {
                  blogListWrap += ' atlanta_tech_news';
                  highlightColor = {backgroundColor: props.highlight_color};
                }

                if(promotionIndex === promotions.length) {
                  promotionIndex = 0
                }
                
                return (
                  <Fragment key={elem}>
                    <Col sm={6} lg={4} >
                      <div className={blogListWrap} style={highlightColor} >
                        <label className='hdng_lbl text-uppercase'>{elem}</label>

                        { mapCategories[elem].map((post, index) => {

                            var displayDate = post.published_at ? post.published_at : post.created_at

                            return (
                              <div key={post.id}>
                                <div className='my-3'>
                                  <a href={post.url} target="_blank" className="post-class" ><p className="m-0" style={textColor} >{post.original_title}</p></a>
                                  <div className="row blog_list_details">
                                    <div className="col-auto">
                                      <span className="author-name" onClick={() => viewAuthor(post.author)} >
                                        <span className="icons">
                                          <img src={edit} />
                                        </span>
                                        {post.author  ? post.author : 'Unknown' }
                                      </span>
                                    </div>
                                    <div className="col-auto">
                                      <span>
                                        <span className="icons">
                                          <img src={calender} />
                                        </span>
                                        {moment(displayDate).tz(process.env.REACT_APP_TIME_ZONE).format('MMMM D, YYYY')}</span>
                                    </div>
                                  </div>
                                </div>

                                {post.image &&
                                  <div className='my-3'>
                                    <img width='100%' alt={"thumbnail-"+post.id} src={post.image} loading="lazy" />
                                  </div>
                                }

                              </div>
                            )

                          })
                        }

                        <div className={ (moreCategories.hasOwnProperty(elem) && moreCategories[elem]['is_less'] == true) ? 'd-flex align-items-center justify-content-between' : ''}>
                          {moreCategories.hasOwnProperty(elem) && moreCategories[elem]['is_less'] == true &&
                            <Link className="">
                              <FontAwesomeIcon icon={icon({name: 'angles-left', style: 'solid'})} className="more_post_link fa-angles-left" data-offset={moreCategories[elem]['offset']} onClick={() => viewMoreLess(elem)} style={textColor} />
                            </Link>
                          }
                          {moreCategories.hasOwnProperty(elem) && moreCategories[elem]['is_more'] == true &&
                            <Button className='more_post_link float-end' variant='' data-offset={moreCategories[elem]['offset']} onClick={() => viewMoreLess(moreCategories[elem]['id'])} style={textColor} >More {elem} posts</Button>
                          }
                        </div>
                      </div>
                    </Col>

                    { promotions.length > 0 && promotionIndex <= promotions.length-1 && !(index === Object.keys(mapCategories).length-1) && ( num % 3 === 0 ) &&
                      
                      <Row className="justify-content-center">
                        <Col sm={4}>
                          <a href={promotions[promotionIndex]['url']} target="_blank">
                            <img className="my-4" width="100%" alt={"promotion-"+promotions[promotionIndex]['id']} src={promotions[promotionIndex++]['image']} />
                          </a>
                        </Col>
                      </Row>
                    }
                </Fragment>
                )

              })

          }
          { !isLoading && isNoRecords && 
            <div className='text-center'>
              <h1 className="ad_page_hdng" style={{color: props.website_color}} >No Results Found :(</h1>
            </div>
          }
          { isLoading && <Loader /> }
        </Row>
      </Container>
    </>
  )
}

const mapStateToProps = state => {
  return {
     keyword: state.search.keyword,
     category: state.search.category
  }
}

export default connect(mapStateToProps, null)(GridView);