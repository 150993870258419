import { configureStore } from '@reduxjs/toolkit'
import searchReducer from './reducers/search.js'
import settingsReducer from './reducers/settings.js'
import categoriesReducer from './reducers/categories.js'

const store = configureStore({
    reducer: {
        search: searchReducer,
        settings: settingsReducer,
        categories: categoriesReducer
    }
})

export default store