import React, { useEffect, useState } from "react";
import {Col, Form, Row, Table, Button , Modal} from "react-bootstrap";
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import Loader from '../../common/loader';
import { GET, PATCH, POST } from '../../../utils/axios.util';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import edit from '../../../assets/images/icons/edit.png';

const PendingPostsPageCtrl = (props) => {

  const { register, handleSubmit, setError, setValue, formState: { errors } } = useForm();
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm()
  const [ image, setImage ] = useState('');
  const [ modalTitle, setModalTitle ] = useState('');
  const [ modalPostId, setModalPostId ] = useState('');
  const [ imageModal, setImageModal ] = useState(false);
  const [ titleModal, setTitleModal ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isNoRecords, setIsNoRecords ] = useState(false)
  const [ posts, setPosts ] = useState([])
  const [ checkedState, setCheckedState ] = useState({})
  const [ allCheckedState, setAllCheckedState ] = useState(false)
  const [ isDisabled, setIsDisabled ] = useState('')
  const [ searchKeyword, setSearchKeyword ] = useState('')

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 25
  const [ currentItems, setCurrentItems ] = useState([])
  const [ pageCount, setPageCount ] = useState(0);

  useEffect(() => {
    
    if(!(posts.length > 0)) {
      getPosts()
    }
    
  },[currentItems, checkedState, itemOffset, itemsPerPage]);

  const getPosts = () => {

    setIsLoading(true)
    let payload = {
        url : `${process.env.REACT_APP_SERVER}/admin/pending-posts?search=${searchKeyword}`
    }
    GET(payload).then(res => {
        if(res.data) { 
            const { data } = res.data
            const pageCount = Math.ceil(data.length / itemsPerPage);
            const endOffset = itemOffset + itemsPerPage;
            let chunk = data.slice(itemOffset, endOffset)
            let checkMap = {}
            for(let x of chunk) {
              checkMap[x.id] = false
            }
            setCheckedState(prevState => {
              return {
                ...prevState,
                ...checkMap
              }
            })
            setIsNoRecords(false)
            setIsLoading(false)
            setPosts(data)
            setPageCount(pageCount)
            setCurrentItems(chunk)
        }
    }).catch(err => {
        setIsLoading(false)
        if(err.response.status === 404) {
          setIsNoRecords(true)
        }
    })
  }

  const setImageInModal = (event) => {
    let src = event.currentTarget.getAttribute('src')
    let imagePostId = event.currentTarget.getAttribute('data-id')
    setModalPostId(imagePostId)
    setImage(src)
    setImageModal(!imageModal)
  }

  const setTitleInModal = (event) => {

    let postId = event.currentTarget.getAttribute('data-id')
    let editType = event.currentTarget.getAttribute('data-type')
    let content = posts.filter(post => post.id == postId)

    setModalPostId(postId)
    setModalTitle(editType)
    if(editType == 'title') {
      setValue('title', content[0]['original_title'])
    }else {
      setValue('tweet', content[0]['public_title'])
    }
    
    setTitleModal(!titleModal)
    
  }

  const onSubmitEdit = (data) => {
    
    data['post_id'] = modalPostId
    let payload = {
        url : `${process.env.REACT_APP_SERVER}/admin/pending-posts/edit`,
        data
    }
    
    PATCH(payload).then(res => {
        toast.success(res.data.message)
        getPosts()
        setTitleModal(false)

    }).catch(err => {
        if(err) {
            const { data } = err.response.data
            let serverErrors = data
            serverErrors.forEach(errItem => {
                setError(errItem.field, {
                    type: "server",
                    message: errItem.message,
                });
            });
        }
    })

  }

  const approveImage = (type) => {

    let imageData = {}
    imageData['post_id'] = modalPostId
    if(type == 'yes') {
      imageData['is_approve'] = 1
    }else {
      imageData['is_approve'] = 0
    }
    let payload = {
        url : `${process.env.REACT_APP_SERVER}/admin/pending-posts/approve-image`,
        data: imageData
    }

    PATCH(payload).then(res => {
        toast.success(res.data.message)
        getPosts()
        setImageModal(false)

    }).catch(err => {
        if(err) {
            const { data } = err.response.data
            let serverErrors = data
            serverErrors.forEach(errItem => {
                setError(errItem.field, {
                    type: "server",
                    message: errItem.message,
                });
            });
        }
    })

  }

  const handleAllCheckBox = (event) => {

    let inverseValue =  !allCheckedState

    let tempCheckstate = {}
    for(let y in checkedState) {
      if(inverseValue == true) {
        tempCheckstate[y] = true
      }else{
        tempCheckstate[y] = false
      }
      
    }
    
    setAllCheckedState(inverseValue)
    setCheckedState(prevState => {
      return {
        ...prevState,
        ...tempCheckstate
      }
    });
    
  }

  const handleSingleCheckBox = (checkId) => {

    let tempCheckstate = {}
    let tempCheckstateArr = []
    for(let y in checkedState) {
      if(y == checkId) {
        tempCheckstate[y] = !checkedState[y]
      }
    }
    let dummyCheck = {
      ...checkedState,
      ...tempCheckstate
    }
    for(let z in dummyCheck) {
      tempCheckstateArr.push(dummyCheck[z])
    }
    if(tempCheckstateArr.every(element => element === true)) {
      setAllCheckedState(true)
    }else {
      setAllCheckedState(false)
    }
    
    setCheckedState(prevState => {
      return {
        ...prevState,
        ...tempCheckstate
      }
    });
  }

  const submitChecked = (event) => {
    let btnType = event.currentTarget.getAttribute('data-type')

    if(Object.keys(checkedState).length > 0) {

      var checkedData = Object.keys(checkedState).filter(key => checkedState[key] === true)

      if(checkedData.length > 0) {

        var payload;
        var data = { post_ids: JSON.stringify(checkedData) }

        if(btnType == 'publish') {
          payload = {
            url : `${process.env.REACT_APP_SERVER}/admin/pending-posts/publish`,
            data
          }
        }else {
          payload = {
            url : `${process.env.REACT_APP_SERVER}/admin/pending-posts/delete`,
            data
          }
        }

        setIsDisabled(true)
        POST(payload).then(res => {
          toast.success(res.data.message)
          setIsDisabled('')
          getPosts()

        }).catch(err => {
            if(err) {
                setIsDisabled('')
                const { data } = err.response.data
                let serverErrors = data

                serverErrors.forEach(errItem => {
                    setError(errItem.field, {
                        type: "server",
                        message: errItem.message,
                    });
                });
            }
        })
      }
    }

  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % posts.length;
    
    const endOffset = newOffset + itemsPerPage;
    let chunk = posts.slice(newOffset, endOffset)
    const pageCount = Math.ceil(posts.length / itemsPerPage);
    let checkMap = {}
    for(let x of chunk) {
      checkMap[x.id] = false
    }
    setCheckedState(prevState => {
      return {
        ...checkMap
      }
    })
    setAllCheckedState(false)
    setPageCount(pageCount)
    setCurrentItems(chunk)
    setItemOffset(newOffset);
  };
  
  const onSearchSubmit = (data) => {
    getPosts()
  }

    return(
        <>
        <section className="admin_page_body">
          <div className="text-center">
            <h1 className="ad_page_hdng" style={{color: props.website_color}} >Pending Posts</h1>
          </div>
          <Form className="mt-3" onSubmit={handleSubmit2(onSearchSubmit)} >
            <Row>
              <Col sm={10}>
                <Form.Group controlId="">
                  <Form.Control type="text" placeholder="Enter search query here" defaultValue={''} name="keyword" {...register2("keyword")} onChange={(event) => {setSearchKeyword(event.target.value); setValue('keyword', event.target.value)} } />
                </Form.Group>
              </Col>
              <Col sm={2}>
                <Form.Group controlId="">
                  <Button variant="danger" size="md" type="submit" style={{backgroundColor: props.website_color}} >SEARCH</Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <div>
            <Button variant="danger" size="md" className="m-2" data-type="publish" onClick={submitChecked} style={{backgroundColor: props.website_color}} disabled={isDisabled} >PUBLISH CHECKED</Button>
            <Button variant="danger" size="md" className="m-2" data-type="delete" onClick={submitChecked} style={{backgroundColor: props.website_color}} disabled={isDisabled} >DELETE CHECKED</Button>
          </div>
          <div className="m-2">
            { isLoading && 
              <Loader />
            }
          <Form>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    {['checkbox'].map((type) => (
                      <div key={`inline-${type}`}>
                        <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={handleAllCheckBox} checked={allCheckedState} />
                      </div>
                    ))}
                  </th>
                  <th width="130">Image</th>
                  <th>Title</th>
                  <th>Tweet</th>
                  <th>Feed</th>
                  <th>Category</th>
                  <th>Preview</th>
                </tr>
              </thead>
              <tbody>
              { (isNoRecords == false) && !isLoading && currentItems.length > 0 &&

                currentItems.map((elem, index) => {

                  return (
                    <tr key={elem.id}>
                      <td>
                        {['checkbox'].map((type) => (
                          <div key={`inline-${type}`}>
                            <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                          </div>
                        ))}
                      </td>
                      <td>
                        {
                          elem.image && 
                          <div className="d-flex flex-column align-items-center">
                            <img data-id={elem.id} onClick={setImageInModal} width="70" src={elem.image} alt={"img-"+elem.id} loading="lazy" />
                            <span className="border border-secondary rounded mt-1 px-1 fs-13" style={{ color: 'black' }}>{elem.image_approve ? 'Approved' : 'Not Approved'}</span>
                          </div>
                        }
                        {
                        !elem.image &&
                          <div className="d-flex flex-column align-items-center">
                            <img data-id={elem.id} width="70" src={elem.dummy_image} alt={"img-"+elem.id} loading="lazy" />
                          </div>
                        }
                      </td>
                      <td>
                        <Link data-id={elem.id} data-type="title" onClick={setTitleInModal} >
                          <span className="icons">
                            <img width={'14px'} src={edit} />
                          </span>
                        </Link>
                        {elem.original_title}
                      </td>
                      <td>
                        <Link data-id={elem.id} data-type="tweet" onClick={setTitleInModal} >
                          <span className="icons">
                            <img width={'14px'} src={edit} />
                          </span>
                        </Link>
                        {elem.public_title}
                      </td>
                      <td>{elem.feed_id ? elem.business_name : null }</td>
                      <td>{elem.category_name}</td>
                      <td>
                        <a href={elem.url} target="_blank">
                          PREVIEW
                        </a>
                      </td>
                      {/* <td width={100}>
                        <div className="d-grid gap-2">
                          <Button variant="danger" size="sm">EDIT</Button>
                          <Button variant="danger" size="sm">DELETE</Button>
                        </div>
                      </td> */}
                    </tr>
                  )
                })
              }
              { isNoRecords && 
                <tr className="text-center">
                  <td colSpan="6" className="fs-1">
                    No Records Found!
                  </td>
                </tr>
              }
              </tbody>
            </Table>
          </Form>

          { (isNoRecords == false) && !isLoading && currentItems.length > 0 &&
                <ReactPaginate 
                  className="justify-content-center mt-4 pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  activeClassName="active"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  breakLabel="..."
                  nextLabel={currentItems[currentItems.length-1]['id'] === posts[posts.length-1]['id'] ? null : 'Next'}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel={currentItems[0]['id'] === posts[0]['id'] ? null : 'Previous'}
                  renderOnZeroPageCount={null}
                />
            }

          </div>
        </section>

        <Modal show={imageModal} onHide={() => setImageModal(false)} centered style={{ fontFamily: props.font_family }} >
          <Modal.Header>
            <Modal.Title className='text_soft_red mx-auto' style={{color: props.website_color}} >Show Image on Site?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <img width="100%" src={image} />
            </div>
            <div className="mt-3">
              <Row>
                <Col className="d-grid">
                  <Button variant="danger" size="md" onClick={event => approveImage('yes')} style={{backgroundColor: props.website_color}} >YES</Button>
                </Col>
                <Col className="d-grid">
                  <Button variant="danger" size="md" onClick={event => approveImage('no')} style={{backgroundColor: props.website_color}} >NO</Button>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>

        <Modal id="pending-modal" show={titleModal} onHide={() => setTitleModal(false)} centered style={{ fontFamily: props.font_family }} >
          <Modal.Header>
            <Modal.Title className='text-capitalize mx-auto' style={{color: props.website_color}} >Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit(onSubmitEdit)} >
              <Form.Group className="mb-3" controlId="">
                <Form.Label >{ modalTitle == "title" ? 'Title' : 'Tweet' }</Form.Label>
                
                { modalTitle === 'title' && 

                  <Form.Control as="textarea" rows={3} name="title" defaultValue={''} onChange={(event) => setValue('title', event.target.value)} {...register('title')} />
                  
                }
                { modalTitle === 'tweet' && 

                  <Form.Control as="textarea" rows={3} name="tweet" defaultValue={''} onChange={(event) => setValue('tweet', event.target.value)} {...register('tweet')} />
                  
                }
                { errors.title && <p className="error">{errors.title.message}</p> }
                { errors.tweet && <p className="error">{errors.tweet.message}</p> }
              </Form.Group>

              <div className='d-grid'>
                <Button variant="danger" size="lg" type="submit" style={{backgroundColor: props.website_color}} >SUBMIT</Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        </>
    )
}

export default PendingPostsPageCtrl;