let initialState = {}

const settingsReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'SETTINGS':
            return {
                ...state,
                ...action.payload
            }
        default: return state
    }
}

export default settingsReducer