import React, { useEffect, useState } from 'react';
import { Container } from "react-bootstrap";
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { settings, categories } from "../redux/actions";
import { connect } from 'react-redux';

import { GET } from '../utils/axios.util';

import HomePageCtrl from '../front/pages/Home';
import DynamicStaticPageCtrl from '../front/pages/Static';
import HomeLayout from '../front/Layout';
import DashboardLayout from '../dashboard/Layout';
import PendingPostsPageCtrl from '../dashboard/pages/pending-post';
import LoginCtrl from '../dashboard/pages/login';
import ResetPassword from '../dashboard/pages/login/resetPassword';
import LogoutCtrl from '../dashboard/common/logout';
import ApprovedPostsPageCtrl from '../dashboard/pages/approved-posts';
import CategoriesPageCtrl from '../dashboard/pages/Categories';
import FeedsPageCtrl from '../dashboard/pages/Feeds';
import PromotionsPageCtrl from '../dashboard/pages/promotions';
import StaticPageCtrl from '../dashboard/pages/static-pages';
import StaticEditPageCtrl from '../dashboard/pages/static-pages/edit';
import StaticCreatePageCtrl from '../dashboard/pages/static-pages/create';
import UsersPageCtrl from '../dashboard/pages/users';
import SettingsPageCtrl from '../dashboard/pages/settings';

const Root = (props) => {

    const dispatch = useDispatch()
    const [currentSettings, setCurrentSettings ] = useState({})
    const [allCategories, setAllCategories ] = useState([])

    useEffect(() => {

        if(Object.keys(currentSettings).length === 0) {
            getSettings()
        }
        
        if(allCategories.length === 0) {
            getCategories()
        }

    },[currentSettings, allCategories, props]);

    const getSettings = () => {

        let payload = {
            url : `${process.env.REACT_APP_SERVER}/admin/settings`
        }
        GET(payload).then(res => {
            if(res.data) { 
                const { data } = res.data
                dispatch(settings(data))
                setCurrentSettings(data)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const getCategories = () => {
        let payload = {
            url : `${process.env.REACT_APP_SERVER}/list-categories`
        }

        GET(payload).then(res => {
            if(res.data) { 
                const { data } = res.data
                dispatch(categories(data))
                setAllCategories(data)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <>
        { Object.keys(currentSettings).length === 0 && 
            
            <Container>

                <div style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                            }} className="fs-1" >
                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                </div>

            </Container>
        }
        { Object.keys(currentSettings).length > 0 && 
            <div style={{ fontFamily: props.font_family }} >
                <Routes>
                    <Route path="/" element={<HomeLayout {...props} />} >
                        <Route index element={<HomePageCtrl {...props} />} />
                        <Route path=":page_slug" element={<DynamicStaticPageCtrl {...props} />} />
                    </Route>

                    <Route path="/admin/login" element={<LoginCtrl {...props} />}></Route>
                    <Route path="/admin/reset-password" element={<ResetPassword {...props} />}></Route>
                    <Route path="/admin" element={<DashboardLayout {...props} />}>
                        
                        <Route path="" element={<PendingPostsPageCtrl {...props} />} />
                        <Route path="pending-posts" element={<PendingPostsPageCtrl {...props} />} />
                        <Route path="approved-posts" element={<ApprovedPostsPageCtrl {...props} />} />
                        <Route path="categories" element={<CategoriesPageCtrl {...props} />} />
                        <Route path="feeds" element={<FeedsPageCtrl {...props} />} />
                        <Route path="promotions" element={<PromotionsPageCtrl {...props} />} />
                        <Route path="static-pages" element={<StaticPageCtrl {...props} />} />
                        <Route path="static-pages/create" element={<StaticCreatePageCtrl {...props} />} />
                        <Route path="static-pages/:id/edit" element={<StaticEditPageCtrl {...props} />} />
                        <Route path="users" element={<UsersPageCtrl {...props} />} />
                        <Route path="settings" element={<SettingsPageCtrl {...props} />} />
                        <Route path="logout" element={<LogoutCtrl {...props} />} />
                    </Route>
                </Routes>
            </div>
        }
        </>
   )

}

const mapStateToProps = state => {
    return {
        ...state.settings
    }
}
  
export default connect(mapStateToProps, null)(Root);