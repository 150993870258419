import React from "react";

function Loader(){

    return(
        <>
            <div className="text-center">
                <div className="lds-facebook"><div></div><div></div><div></div></div>
            </div>
        </>
    )
}

export default Loader