import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { POST } from '../../../utils/axios.util';

import HeaderCtrl from '../../../front/common/Header';
import FooterCtrl from '../../../front/common/Footer';

const LoginCtrl = (props) => {

    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const onSubmit = (data) => {
        let payload = {
            url : `${process.env.REACT_APP_SERVER}/admin/login`,
            data
        }
        
        POST(payload).then(res => {
            localStorage.setItem('user', JSON.stringify(res.data.data))
            navigate("/admin/pending-posts") 
        }).catch(err => {
            if(err) {
                const { data } = err.response.data
                let serverErrors = data
                serverErrors.forEach(errItem => {
                    setError(errItem.field, {
                        type: "server",
                        message: errItem.message,
                    });
                });
            }
        })
    }

    return(
        <>
            <div className="front_layouts admin">
                <HeaderCtrl {...props} />
                <section>
                <Container fluid="sm">
                    <Row className="justify-content-center login-div">
                        <Col sm={6} lg={6}>
                            <div >
                                <div className="modal-header">
                                    <div className="text_soft_red mx-auto modal-title h4" style={{color: props.website_color}} >Log in</div>
                                </div>
                                <Form onSubmit={handleSubmit(onSubmit)} >
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>EMAIL</Form.Label>
                                        <Form.Control  type="text" name="email" {...register("email", 
                                            {
                                                required: "Email is required", 
                                                pattern: { 
                                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: "Invalid email address"
                                                }
                                            }
                                        )} />
                                        {errors.email ?.message && <p className="error">{errors.email ?.message}</p> }
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" name="password" {...register("password", { required: "Password is required" }
                                        )} />
                                        {errors.password ?.message && <p className="error">{errors.password ?.message}</p> }
                                    </Form.Group>
                                    <NavLink to="/admin/reset-password" className="reset-password-text">
                                        <p>Forgot Password ?</p>
                                    </NavLink>
                                    <div className="d-grid">
                                        <Button variant="danger" size="lg" type="submit" style={{backgroundColor: props.website_color}} >Log in</Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
                </section>

                <footer>
                    <FooterCtrl {...props} />
                </footer>
            </div>
        </>
    )
}
export default LoginCtrl;