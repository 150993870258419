import React from "react";

function Loader(){

    return(
        <>
            <div style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
                    }} className="fs-1" >
              <div className="lds-facebook"><div></div><div></div><div></div></div>
              </div>
        </>
    )
}

export default Loader