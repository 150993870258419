import React from 'react';
import {Modal,Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { POST } from '../../utils/axios.util';

const SubscribeModalCtrl = (props) => {

    const { register, handleSubmit, setError, reset, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        let payload = {
            url : `${process.env.REACT_APP_SERVER}/submit-subscription`,
            data
        }
        
        POST(payload).then(res => {
            toast.success(res.data.message)
            reset()
            props.onHide()

        }).catch(err => {
            if(err) {
                const { data } = err.response.data
                let serverErrors = data
                serverErrors.forEach(errItem => {
                    setError(errItem.field, {
                        type: "server",
                        message: errItem.message,
                    });
                });
            }
        })
    }

    return(
        <>
            <Modal {...props} aria-labelledby="Subscribe To Our Mailing List" centered  keyboard={false} style={{ fontFamily: props.font_family }} >
                <Modal.Header>
                    <Modal.Title className='text_soft_red mx-auto' style={{color: props.website_color}} >Subscribe To Our <br/> Mailing List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                 

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>EMAIL ADDRESS</Form.Label>
                        <Form.Control type="text" placeholder="email@address.com" {...register("email", 
                                {
                                    required: "Email Address is required", 
                                    pattern: { 
                                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Invalid email address"
                                    }
                                }
                            )} name="email" />

                        {errors.email ?.message && <p className="error">{errors.email ?.message}</p> }
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>FIRST NAME</Form.Label>
                        <Form.Control type="text" placeholder="" {...register("first_name", 
                                {
                                    required: "First name is required"
                                }
                            )} name="first_name" />

                        {errors.first_name ?.message && <p className="error">{errors.first_name ?.message}</p> }
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>LAST NAME</Form.Label>
                        <Form.Control type="text" placeholder="" {...register("last_name")} name="last_name" />
                    </Form.Group>
                    <div className='d-grid'>
                        <Button variant="danger" size="lg" type="submit" style={{backgroundColor: props.website_color}} >SUBSCRIBE</Button>
                    </div>
                </Form>

                </Modal.Body>
            </Modal>
      </>
    )
}

export default SubscribeModalCtrl;