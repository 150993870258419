import React, { useEffect, useState, useTransition } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactPaginate from 'react-paginate';

import { GET, POST, PATCH } from '../../../utils/axios.util';
import Loader from '../../common/loader';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UsersPageCtrl(props){

  const { register, handleSubmit, setError, setValue, formState: { errors } } = useForm();

  const [ users, setUsers ] = useState([])
  const [ userModal, setUserModal ] = useState(false)
  const [ modalUserId, setModalUserId ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isNoRecords, setIsNoRecords ] = useState(false)
  const [ checkedState, setCheckedState ] = useState({})
  const [ allCheckedState, setAllCheckedState ] = useState(false)
  const [ formType, setFormType ] = useState('')

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 25
  const [ currentItems, setCurrentItems ] = useState([])
  const [ pageCount, setPageCount ] = useState(0);

  useEffect(() => {
    if(!(users.length > 0)) {
        getUsers()
    }

  },[currentItems, checkedState, itemOffset, itemsPerPage]);

  const getUsers = () => {

    setIsLoading(true)
    let payload = {
      url : `${process.env.REACT_APP_SERVER}/admin/users`
    }
    GET(payload).then(res => {
      if(res.data) { 
        const { data } = res.data
        const pageCount = Math.ceil(data.length / itemsPerPage);
        const endOffset = itemOffset + itemsPerPage;
        let chunk = data.slice(itemOffset, endOffset)
        let checkMap = {}

        for(let x of chunk) {
          checkMap[x.id] = false
        }
        setCheckedState(prevState => {
          return {
            ...prevState,
            ...checkMap
          }
        })
        setIsNoRecords(false)
        setIsLoading(false)
        setUsers(data)
        setPageCount(pageCount)
        setCurrentItems(chunk)
      }
    }).catch(err => {
      setIsLoading(false)
      if(err.response.status === 404) {
        setIsNoRecords(true)
      }
    })
  }

  const setupModal = (event) => {

    let userId = event.currentTarget.getAttribute('data-id')
    let buttonType = event.currentTarget.getAttribute('data-type')
    
    if(buttonType === 'create') {
      setValue('first_name', '');
      setValue('last_name', '');
      setValue('email', '');
    }else {
      let filterUsers = users.filter(usr => {
        return usr.id == userId
      })

      setValue('first_name', filterUsers[0]['first_name']);
      setValue('last_name', filterUsers[0]['last_name']);
      setValue('email', filterUsers[0]['email']);

    }
    setModalUserId(userId)
    setFormType(buttonType)
    setUserModal(true)
  }

  const onSubmit = (data) => {

    var REQUEST;
    var slug;
    if(formType === 'create') {
      REQUEST = POST
      slug = 'create'
    }else {
      data['user_id'] = modalUserId
      REQUEST = PATCH
      slug = 'edit'
    }

    let payload = {
        url : `${process.env.REACT_APP_SERVER}/admin/users/${slug}`,
        data
    }
    
    REQUEST(payload).then(res => {
      toast.success(res.data.message)
      getUsers()
      setUserModal(false)

    }).catch(err => {
        if(err) {
            const { data } = err.response.data
            let serverErrors = data

            serverErrors.forEach(errItem => {
                setError(errItem.field, {
                    type: "server",
                    message: errItem.message,
                });
            });
        }
    })
    
  }

  const handleAllCheckBox = (event) => {

    let inverseValue =  !allCheckedState

    let tempCheckstate = {}
    for(let y in checkedState) {
      if(inverseValue == true) {
        tempCheckstate[y] = true
      }else{
        tempCheckstate[y] = false
      }
      
    }
    
    setAllCheckedState(inverseValue)
    setCheckedState(prevState => {
      return {
        ...prevState,
        ...tempCheckstate
      }
    });
    
  }

  const handleSingleCheckBox = (checkId) => {

    let tempCheckstate = {}
    let tempCheckstateArr = []
    for(let y in checkedState) {
      if(y == checkId) {
        tempCheckstate[y] = !checkedState[y]
      }
    }
    let dummyCheck = {
      ...checkedState,
      ...tempCheckstate
    }
    for(let z in dummyCheck) {
      tempCheckstateArr.push(dummyCheck[z])
    }
    if(tempCheckstateArr.every(element => element === true)) {
      setAllCheckedState(true)
    }else {
      setAllCheckedState(false)
    }
    
    setCheckedState(prevState => {
      return {
        ...prevState,
        ...tempCheckstate
      }
    });
  }

  const submitChecked = () => {

    if(Object.keys(checkedState).length > 0) {

      var checkedData = Object.keys(checkedState).filter(key => checkedState[key] === true)

      if(checkedData.length > 0) {

        var data = { user_ids: JSON.stringify(checkedData) }

        let payload = {
          url : `${process.env.REACT_APP_SERVER}/admin/users/delete`,
          data
        }

        POST(payload).then(res => {
          toast.success(res.data.message)
          getUsers()

        }).catch(err => {
            if(err) {
                const { data } = err.response.data
                let serverErrors = data

                serverErrors.forEach(errItem => {
                    setError(errItem.field, {
                        type: "server",
                        message: errItem.message,
                    });
                });
            }
        })
      }
    }

  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % users.length;
    
    const endOffset = newOffset + itemsPerPage;
    let chunk = users.slice(newOffset, endOffset)
    const pageCount = Math.ceil(users.length / itemsPerPage);
    let checkMap = {}
    for(let x of chunk) {
      checkMap[x.id] = false
    }
    setCheckedState(prevState => {
      return {
        ...checkMap
      }
    })
    setAllCheckedState(false)
    setPageCount(pageCount)
    setCurrentItems(chunk)
    setItemOffset(newOffset);
  };

    return(
        <>
          <section className="admin_page_body">
            <div className="text-center">
              <h1 className="ad_page_hdng" style={{color: props.website_color}} >Users</h1>
            </div>
            <div>
              <Button variant="danger" size="md" className="m-2" data-type="create" onClick={setupModal} style={{backgroundColor: props.website_color}} >CREATE NEW USER</Button>
              <Button variant="danger" size="md" className="m-2" onClick={submitChecked}  style={{backgroundColor: props.website_color}} >DELETE CHECKED</Button>
            </div>
            <div className="m-2">
              { isLoading && 
                <Loader />
              } 
            <Form>
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      {['checkbox'].map((type) => (
                        <div key={`inline-${type}`}>
                          <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={handleAllCheckBox} checked={allCheckedState} />
                        </div>
                      ))}
                    </th>
                    <th>Email Address</th>
                    <th>First Name</th>
                    <th colSpan={2}>Last Name</th>
                  </tr>
                </thead>

                <tbody>
                { (isNoRecords == false) && !isLoading && currentItems.length > 0 &&

                  currentItems.map((elem, index) => {

                    return (
                      <tr key={elem.id}>
                        <td>
                          {['checkbox'].map((type) => (
                            <div key={`inline-${type}`}>
                              <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                            </div>
                          ))}
                        </td>
                        <td>{elem.email}</td>
                        <td>{elem.first_name}</td>
                        <td>{elem.last_name}</td>
                        <td width={100}>
                        <div className="d-grid gap-2">
                          <Button variant="danger" size="sm" data-id={elem.id} data-type="edit" onClick={setupModal} style={{backgroundColor: props.website_color}} >EDIT</Button>
                          {/* <Button variant="danger" size="sm">DELETE</Button> */}
                        </div>
                        </td>
                      </tr>
                    )
                  })
                }
                { isNoRecords && 
                  <tr className="text-center">
                    <td colSpan="6" className="fs-1">
                      No Records Found!
                    </td>
                  </tr>
                }
                </tbody>
              </Table>
            </Form>

            { (isNoRecords == false) && !isLoading && currentItems.length > 0 &&
                <ReactPaginate 
                  className="justify-content-center mt-4 pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  activeClassName="active"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  breakLabel="..."
                  nextLabel={currentItems[currentItems.length-1]['id'] === users[users.length-1]['id'] ? null : 'Next'}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel={currentItems[0]['id'] === users[0]['id'] ? null : 'Previous'}
                  renderOnZeroPageCount={null}
                />
            }

            </div>
          </section>

          <Modal show={userModal} aria-labelledby="Category" centered keyboard={false} onHide={() => setUserModal(false)} style={{ fontFamily: props.font_family }} >
              <Modal.Header>
                <Modal.Title className='text_soft_red mx-auto' style={{color: props.website_color}} >User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)} >
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control type="text" placeholder="" {...register("first_name", { required: true })} name="first_name" defaultValue={''} onChange={(event) => setValue('first_name', event.target.value)} />

                      {errors.first_name?.type === 'required' && <p className="error">First name is required</p>}
                      {errors.first_name?.type === 'server' && <p className="error">{errors.first_name.message}</p>}

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control type="text" placeholder="" {...register("last_name")} name="last_name" defaultValue={''} onChange={(event) => setValue('last_name', event.target.value)} />

                      {errors.last_name?.type === 'required' && <p className="error">Last name is required</p>}
                      {errors.last_name?.type === 'server' && <p className="error">{errors.last_name.message}</p>}

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>EMAIL</Form.Label>
                        <Form.Control type="email" placeholder="" defaultValue={''} {...register("email", 
                            {
                                required: "Email Address is required", 
                                pattern: { 
                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Invalid email address"
                                }
                            }
                        )} name="email" onChange={(event) => setValue('email', event.target.value)} />

                        {errors.email ?.message && <p className="error">{errors.email ?.message}</p> }

                    </Form.Group>
                    <div className='d-grid'>
                      <Button variant="danger" size="lg" type="submit" style={{backgroundColor: props.website_color}} >SUBMIT</Button>
                    </div>
                </Form>
              </Modal.Body>
            </Modal>
        </>
    )
}

export default UsersPageCtrl;