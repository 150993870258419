import React from "react";
import { Link, Outlet } from "react-router-dom";
import HeaderCtrl from '../common/Header';
import FooterCtrl from '../common/Footer';
import { connect } from 'react-redux';

const HomeLayout = (props) => {

    return(
        <>
            <div className="front_layouts">
                <HeaderCtrl {...props} />
                <section>
                    <Outlet />
                </section>
                <footer>
                <FooterCtrl {...props} />
                </footer>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        ...state.settings
    }
}
  
export default connect(mapStateToProps, null)(HomeLayout);