import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GET, POST } from '../../utils/axios.util';

const SharePostModalCtrl = (props) => {

    const [ categories, setCategories ] = useState([])
    const [ options, setOptions ] = useState(null)
    
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    useEffect(() => {
       
        if(!(categories.length > 0)) {
            getCategories()
        }
        if(categories.length > 0 && !options) {
            let optionValues =  categories.map((e)=>{
                return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                )
            }) 
            setOptions(optionValues)
        }

    },[ categories, options ]);

    const getCategories = () => {
        let payload = {
            url : `${process.env.REACT_APP_SERVER}/list-categories`
        }

        GET(payload).then(res => {
            if(res.data) { 
                const { data } = res.data
                setCategories(data)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const onSubmit = (data) => {
        let payload = {
            url : `${process.env.REACT_APP_SERVER}/submit-post`,
            data
        }
        
        POST(payload).then(res => {
            toast.success(res.data.message)
            reset()
            props.onHide()

        }).catch(err => {
            console.log(err)
        })
    }

    return(
        <>
            <Modal {...props} aria-labelledby="Add Your Blog" centered keyboard={false} style={{ fontFamily: props.font_family }} > 
                <Modal.Header>
                    <Modal.Title className='text_soft_red mx-auto' style={{color: props.website_color}} >Share a Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>TITLE</Form.Label>
                        <Form.Control type="text" placeholder="Blog title goes here" {...register("post_title", { required: true })} name="post_title" />

                        {errors.post_title?.type === 'required' && <p className="error">Post title is required</p>}

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>URL</Form.Label>
                        <Form.Control type="text" placeholder="" {...register("url", {
                            required: "URL is required", 
                            pattern: { 
                                value: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig,
                                message: "Invalid URL"
                            }
                        })} name="url" />

                        { errors.url && <p className="error">{errors.url.message}</p> }

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>AUTHOR</Form.Label>
                        <Form.Control type="text" placeholder="" {...register("author", { required: 'Author is required',
                        pattern: {
                            value: /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/,
                            message: "Only letters and numbers are allowed"
                        }
                        })} name="author" />

                        {errors.author && <p className="error">{errors.author.message}</p>}

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>CATEGORY</Form.Label>
                        <Form.Select id="" {...register("category", { required: true })} name="category" >
                            <option value="">-- Choose a Category --</option>
                            {options}
                        </Form.Select>

                        {errors.category?.type === 'required' && <p className="error">Category is required</p>}

                    </Form.Group>
                    <div className='d-grid'>
                        <Button variant="danger" size="lg" type="submit" style={{backgroundColor: props.website_color}} >SUBMIT</Button>
                    </div>
                </Form>

                </Modal.Body>
            </Modal>
      </>
    )
}

export default SharePostModalCtrl;