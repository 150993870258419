import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { connect, useDispatch } from 'react-redux';
import { search } from "../../../redux/actions";
import { GET } from '../../../utils/axios.util';

import ListView from './listView'
import GridView from './gridView'

const HomePageCtrl = (props) => {

  var textColor = {color: props.website_color}
  const [ filteredPromotions, setFilteredPromotions ] = useState([])
  const [ promotions, setPromotions ] = useState([])
  const [ categories, setCategories ] = useState({})
  const [ view, setView ] = useState({ list: true, grid: false })

  var presetStyle = {
    display: 'block',
    border: `2px solid ${props.website_color}`,
    borderRadius: '5px'
  }

  const [ style, setStyle ] = useState({
    list: presetStyle,
    grid: {}
  })

  const dispatch = useDispatch()

  useEffect(() => {

    toggleDropdown()

    if(!promotions.length > 0) {
      getPromotions()
    }

    if(!Object.keys(categories).length > 0) {
      getCategories()
    }

    if(props.template_style === 'primary' || props.keyword || props.category || props.author) {
      setView(prevState => {
        return {
          ...prevState,
          list: true, 
          grid: false 
        }
      })
      setStyle(prevState => {
        return {
          ...prevState,
          list: presetStyle,
          grid: {}
        }
      })
    }else {
      setView(prevState => {
        return {
          ...prevState,
          list: false, 
          grid: true 
        }
      })
      setStyle(prevState => {
        return {
          ...prevState,
          grid: presetStyle,
          list: {}
        }
      })
    }

  },[promotions, categories, props.keyword, props.category, props.author]);

  const getPromotions = () => {
    let payload = {
        url : `${process.env.REACT_APP_SERVER}/get-promotions`
    }
    GET(payload).then(res => {
        if(res.data) { 
          const { data } = res.data
          
          if(data.length > 2) {
            let slicedPromotions = data.slice(1, data.length-1)
            setFilteredPromotions(slicedPromotions)
          }
          setPromotions(data)
        }
    }).catch(err => {
        console.log(err)
    })
  }

  const getCategories = () => {
    let payload = {
        url : `${process.env.REACT_APP_SERVER}/list-categories`
    }

    GET(payload).then(res => {
        if(res.data) { 
            const { data } = res.data
            let mapCat = {}
            for(let ctgry of data) {
              mapCat[ctgry.id] = {
                ...ctgry
              }
            }
            setCategories(mapCat)
        }
    }).catch(err => {
        console.log(err)
    })
}

  const toggleList = () => {
    setView({
      list: true,
      grid: false
    })
    setStyle({
      list: presetStyle,
      grid: {}
    })
  }

  const toggleGrid = () => {
    setView({
      list: false,
      grid: true
    })
    setStyle({
      list: {},
      grid: presetStyle
    })
  }

  const toggleDropdown = () => {
    let navDiv = document.getElementById('navbarScroll')
    let searchBarDiv = document.getElementById('searchDIv')
    let navToggleButton = document.getElementById('menuButton')
    let searchButton = document.getElementById('searchButton')
    navDiv.classList.contains('show') && navToggleButton.click()
    searchBarDiv.classList.contains('show') && searchButton.click()
  };

  return(
      
      <>
        <Container fluid="sm">
          { promotions.length > 0 &&
            <Row className="justify-content-center">
              <Col sm={4}>
                <a href={promotions[0]['url']} target="_blank">
                  <img className="my-4" width="100%" alt={promotions[0]['image_originalname']} src={promotions[0]['image']} />
                </a>
              </Col>
            </Row>
          }
          <Row>
            <Col sm={12} lg={12}>
              <div className='text-center'>
                { props.keyword && 
                  <h1 className="ad_page_hdng" style={textColor} >
                    Search Results for: {props.keyword}
                  </h1> 
                }
                {
                  Object.keys(categories).length > 0 && props.category && 
                  <h1 className="ad_page_hdng" style={textColor} >
                    Category: {categories[props.category]['name']}
                  </h1> 
                }
                { props.author && 
                  <h1 className="ad_page_hdng" style={textColor} >
                    Author: {props.author}
                  </h1> 
                }
              </div>
            </Col>
          </Row> 

          <Row>
              <div className="d-flex justify-content-end">
                <FontAwesomeIcon icon={icon({name: 'list', style: 'solid'})} className="fa-solid fa-list" onClick={toggleList} style={{
                  ...style.list,
                  color: props.website_color
                }} />
                <FontAwesomeIcon icon={icon({name: 'table-cells', style: 'solid'})} className="fa-solid fa-table-cells" onClick={toggleGrid} style={{
                  ...style.grid,
                  color: props.website_color
                }} />
              </div>
            </Row>

          <Row>
            {/* <div className="d-flex justify-content-between view-icons"> */}
            <Col lg={12}>
              { props.keyword &&
                <Col sm={12}>
                  <p className="blog_list m-2 p-2" style={{...textColor, cursor: 'pointer', width: 'fit-content'}} onClick={() => dispatch(search('', props.category, props.author))} >
                    Search: "{props.keyword}"
                    <FontAwesomeIcon icon={icon({name: 'circle-xmark', style: 'regular'})} className="mx-2" />
                  </p>
                </Col>
              }
              {
                Object.keys(categories).length > 0 && props.category && 
                <Col sm={12}>
                  <p className="blog_list m-2 p-2" style={{...textColor, cursor: 'pointer', width: 'fit-content'}} onClick={() => dispatch(search(props.keyword , '', props.author))} >
                    Category: "{categories[props.category]['name']}" 
                    <FontAwesomeIcon icon={icon({name: 'circle-xmark', style: 'regular'})} className="mx-2" />
                  </p>
                </Col>
              }
              {
                props.author && 
                <Col sm={12}>
                  <p className="blog_list m-2 p-2" style={{...textColor, cursor: 'pointer', width: 'fit-content'}} onClick={() => dispatch(search(props.keyword, props.category, ''))} >
                    Author: "{props.author}" 
                    <FontAwesomeIcon icon={icon({name: 'circle-xmark', style: 'regular'})} className="mx-2" />
                  </p>
                </Col>
              }
            {/* </div> */}
            </Col>
          </Row>
        </Container>

        <ListView show={view.list} promotions={filteredPromotions} {...props} />
        <GridView show={view.grid} onToggleList={toggleList} promotions={filteredPromotions}  {...props}  />
        { promotions.length > 1 &&
        <Container fluid>
          <Row className="justify-content-center">
            <Col sm={4}>
              <a href={promotions[promotions.length-1]['url']} target="_blank">
                <img className="my-4" width="100%" alt={promotions[promotions.length-1]['image_originalname']} src={promotions[promotions.length-1]['image']} />
              </a>
            </Col>
          </Row>
        </Container>
        }
      </>
  )
}

const mapStateToProps = state => {
  return {
     keyword: state.search.keyword,
     category: state.search.category,
     author: state.search.author
  }
}

export default connect(mapStateToProps, null)(HomePageCtrl);