import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { connect, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { search } from "../../../redux/actions";

import { GET } from '../../../utils/axios.util';
import Loader from '../../common/loader';

import edit from '../../../assets/images/icons/edit.png';
import arrow from '../../../assets/images/icons/arrow.png';
import calender from '../../../assets/images/icons/calender.png';
import folder from '../../../assets/images/icons/folder.png';

const ListView = (props) => {

  const [ posts, setPosts ] = useState([])
  const [ isMore, setIsMore ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isLoadMore, setIsLoadMore ] = useState(false)
  const [ offset, setOffset ] = useState(0)
  const [ isNoRecords, setIsNoRecords ] = useState(false)
  const [ isSearchedPosts, setIsSearchedPosts ] = useState(false)
  const [ searchKeyword, setSearchKeyword ] = useState('')
  const [ searchCategory, setSearchCategory ] = useState('')
  const [ searchAuthor, setSearchAuthor ] = useState('')
  const [ searchResults, setSearchResults ] = useState([])
  const [ promotions, setPromotions ] = useState(props.promotions)

  const prevPostIds = useRef([])
  const dispatch = useDispatch()
  
  var textColor = {color: props.website_color}
  var isMoreLoading = false
  var promotionIndex = 0
  
  useEffect(() => {

    if(!(posts.length > 0) || !(searchKeyword === props.keyword) || !(searchCategory === props.category) || !(searchAuthor === props.author) ) {
      
      setSearchKeyword(() => {
        return props.keyword
      })
      setSearchCategory(() => {
        return props.category
      })
      setSearchAuthor(() => {
        return props.author
      })

      prevPostIds.current = []
      setPosts([])
      setOffset(0)
      getPosts(0)
    }

    setPromotions(props.promotions)

  },[isSearchedPosts, props.promotions, props.keyword, props.category, props.author]);

  const getPosts = (offset) => {

    setIsLoading(() => !isMoreLoading ? true : false)

    let finalUrl = ''
    if(prevPostIds.current.length > 0) {

      finalUrl = `${process.env.REACT_APP_SERVER}/list-posts?${prevPostIds.current.map((n, index) => `postIds[${index}]=${n}`).join('&')}&offset=${offset}&keyword=${props.keyword}&category=${props.category}&author=${props.author}`
    }else {

      finalUrl = `${process.env.REACT_APP_SERVER}/list-posts?offset=${offset}&keyword=${props.keyword}&category=${props.category}&author=${props.author}`
    }

    let payload = {
      url: finalUrl
    }

    GET(payload).then(res => {
        if(res.data) { 
            const { data } = res.data
            
            if(data.length > 0) {

              let sliceData = data.slice(0, 10)
              let newPosts = []

              if(props.keyword || props.category || props.author) {

                setIsMore(false)
                setIsLoadMore(false)
                isMoreLoading  = false
                if(props.keyword === searchKeyword && props.category === searchCategory && props.author === searchAuthor){
                  newPosts = searchResults.concat(sliceData)
                }else{
                  newPosts = newPosts.concat(sliceData)
                }
                setSearchResults(newPosts)
                setIsSearchedPosts(true)

              }else{

                setIsMore(false)
                setIsLoadMore(false)
                isMoreLoading  = false
                if(searchResults.length > 0) {

                  prevPostIds.current = []
                  setSearchResults([])
                  setOffset(0)
                  setPosts([])
                  setIsSearchedPosts(false)

                }else{

                  newPosts = posts.concat(sliceData)
                }
              }
              let postIds = []
              for(let pst of newPosts) {
                postIds.push(pst.id)
              }

              prevPostIds.current = postIds
              setIsNoRecords(false)
              setIsLoading(false)
              setIsLoadMore(false)
              isMoreLoading  = false

              setPosts(newPosts)
            }
            if(data.length > 10) {
              setIsMore(true)
            }
           
        }
    }).catch(err => {
        setIsLoading(false)
        setIsLoadMore(false)
        if(err.response.status === 404) {
          setIsMore(false)
          setIsNoRecords(true)
        }
    })
  }

  const morePosts = () => {

    var newOffset = 0
    if(offset === 0) {
      newOffset = 10
    }else {
      newOffset = offset + 10
    }
    
    isMoreLoading = true
    setIsLoadMore(true)
    setOffset(newOffset)
    getPosts(newOffset)

  }

  const applyFilter = (type, value) => {

    prevPostIds.current = []
    if(type === 'author') {
      dispatch(search(props.keyword, props.category, value))
    }
    if(type === 'category') {
      dispatch(search(props.keyword, value, props.author))
    }

  }

    return(
        <>
          <InfiniteScroll
            dataLength={posts.length}
            next={morePosts}
            hasMore={isMore}
          >
            <Container fluid="sm" style={{ display: !props.show ? 'none' : 'block' }} >
            
              <Row>
                <Col sm={12}>
                  { (isNoRecords == false) && !isLoading &&  posts.length > 0 &&
                    posts.map((elem, index) => {
                      
                      var highlightColor = {}
                      let num = index + 1
                      let blogListWrap = 'blog_list'

                      if(elem.image) {
                        blogListWrap += ' blog_with_thumbnail'
                      }
                      if(elem.is_highlighted === 1) {
                        blogListWrap += ' atlanta_tech_news'
                        highlightColor = {backgroundColor: props.highlight_color};
                      }
                      if(promotionIndex === promotions.length || index === posts.length-1 ) {
                        promotionIndex = 0
                      }
                      var displayDate = elem.published_at ? elem.published_at : elem.created_at

                      var title = elem.original_title
                      if(elem.original_title.length > 136){
                        title = elem.original_title.substring(0, 136) + '...'
                      }

                      return (
                        
                        <div key={elem.id}>

                          <div className={blogListWrap} style={highlightColor} >
                            <span className="thumbnail_img">
                              {elem.image && 
                                <img alt={"thumbnail"+elem.id} src={elem.image} loading="lazy" />
                              }
                            </span>
                            <div className="blog_list_body" >
                              <a href={elem.url} target="_blank" className="post-class" ><p className="m-0" style={textColor} >{title}</p></a>
                              <div className="row blog_list_details">
                                <div className="col-sm-12 col-md-auto">
                                  <span className="author-name" onClick={() => applyFilter('author', elem.author)} >
                                    <span className="icons">
                                      <img alt="author" src={edit} />
                                    </span>
                                    {elem.author  ? elem.author : 'Unknown' }
                                  </span>
                                </div>
                                <div className="col-sm-12 col-md-auto">
                                  <span>
                                    <span className="icons">
                                      <img alt="published_at" src={calender} />
                                    </span>
                                    {moment(displayDate).tz(process.env.REACT_APP_TIME_ZONE).format('MMMM D, YYYY')}</span>
                                </div>
                                <div className="col-sm-12 col-md-auto">
                                  <span className="blog-category" onClick={() => applyFilter('category', elem.category_id)} >
                                    <span className="icons">
                                      <img alt="category" src={folder} />
                                    </span>
                                    {elem.category_name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          { promotions.length > 0 && promotionIndex <= promotions.length-1 && !(index === posts.length-1) && ( num % 5 === 0 ) &&

                            <Row className="justify-content-center">
                              <Col sm={4}>
                                <a href={promotions[promotionIndex]['url']} target="_blank">
                                  <img className="my-4" width="100%" alt={"promotion-"+promotions[promotionIndex]['id']} src={promotions[promotionIndex++]['image']} />
                                </a>
                              </Col>
                            </Row>
                          }
                        </div>
                      )
                    })
                  }
                </Col>
              </Row>
              
              { isMore && !isLoading && !isLoadMore &&
                <Row>
                  <Col sm={12} className="d-flex justify-content-center">
                    <Link onClick={() => morePosts() }>
                      <img className="my-4" src={arrow} />
                    </Link>
                  </Col>
                </Row>
              }
              { isNoRecords && 
                <div className='text-center'>
                  <h1 className="ad_page_hdng" style={{color: props.website_color}} >No Results Found :(</h1>
                </div>
              }
              { isLoading && <Loader /> }
              { isLoadMore && 
                <Row>
                  <Col sm={12} className="d-flex justify-content-center">
                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                  </Col>
                </Row>
              }
            </Container>
          </InfiniteScroll>
        </>
    )
}

const mapStateToProps = state => {
  return {
     keyword: state.search.keyword,
     category: state.search.category,
     author: state.search.author
  }
}

export default connect(mapStateToProps, null)(ListView);