import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { GET } from '../../../utils/axios.util';
import Loader from '../../common/loader';

const DynamicStaticPageCtrl = () => {

  const params = useParams();
  const [ page, setPage ] = useState({})
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isNoRecords, setIsNoRecords ] = useState(false)
  const [ slug, setSlug ] = useState(params.page_slug)

  useEffect(() => {

    toggleDropdown()

    if(Object.keys(page).length === 0 || slug !== params.page_slug ) {
      getPage()
    }

    setSlug(() => params.page_slug)

  },[page, params.page_slug]);

  const getPage = () => {

    setIsLoading(true)
    let payload = {
      url : `${process.env.REACT_APP_SERVER}/page/${params.page_slug}`
    }
    GET(payload).then(res => {
      if(res.data) { 
        const { data } = res.data
        setIsNoRecords(false)
        setIsLoading(false)
        setPage(data)
      }
    }).catch(err => {
      setIsLoading(false)
      if(err.response.status === 404) {
        setIsNoRecords(true)
      }
    })
  }

  const toggleDropdown = () => {
    let navDiv = document.getElementById('navbarScroll')
    let searchBarDiv = document.getElementById('searchDIv')
    let navToggleButton = document.getElementById('menuButton')
    let searchButton = document.getElementById('searchButton')
    navDiv.classList.contains('show') && navToggleButton.click()
    searchBarDiv.classList.contains('show') && searchButton.click()
  };

  function createMarkup() {
    
    let content = page.content.replace(/\\'/g,"'")
    return {__html: content};
  }
  
    return(
        <>
          { isLoading && 
            <Loader />
          }
          { (isNoRecords == false) && !isLoading && Object.keys(page).length > 0 && 
            <div dangerouslySetInnerHTML={createMarkup()} />
          }
          { isNoRecords && 
            <div className='text-center'>
              <h1 className="ad_page_hdng">No Results Found :(</h1>
            </div>
          }
        </>
    )
}


export default DynamicStaticPageCtrl;