import React, { useState } from 'react';
import { NavLink, useLocation  } from 'react-router-dom';
import styled from "styled-components";

function SideMenuBarCtrl(props) {

  const pathname = useLocation().pathname
  var website_color = props.website_color
  let staticPagePattern = /\/admin\/static-pages/i

  const CustomDiv = styled.div`
  :hover {
    background-color: ${website_color};
    color: #ffffff !important;
    &:hover span {
      fill: #ffffff !important;
    }
  }
  `
  return (
    <>
      <div className='user_side_bar'>
        <a href="/">
          <img className='user_side_bar_brand' src={props.logo} />
        </a>
        <div>
          <NavLink className="nav_link"  to="pending-posts" >
            <CustomDiv className='nav_link_cntnt' style={pathname == '/admin/pending-posts' ? {color: '#ffffff', backgroundColor: props.website_color} : {color: website_color} } >
              <span className='nav_link_icon' style={pathname == '/admin/pending-posts' ? {color: '#ffffff'} : {fill: website_color} } >
                <svg xmlns="http://www.w3.org/2000/svg" width="23.862" height="23.86" viewBox="0 0 23.862 23.86">
                  <path id="Path_40" data-name="Path 40" d="M16.922,1.446a2.991,2.991,0,0,1,4.227,0l1.838,1.84a2.988,2.988,0,0,1,0,4.225L20.729,9.769,14.664,3.7Zm2.753,9.377L8.795,21.7A4.09,4.09,0,0,1,7.05,22.734L1.436,24.386a1.052,1.052,0,0,1-1.108-.322A1.027,1.027,0,0,1,.046,23L1.7,17.383a4.114,4.114,0,0,1,1.036-1.745L13.61,4.76Z" transform="translate(0 -0.572)" />
                </svg>  
              </span>
              <span className='nav_link_text'>Pending Posts</span>
            </CustomDiv>
          </NavLink>

          <NavLink className="nav_link"  to="approved-posts">
            <CustomDiv className='nav_link_cntnt' style={pathname == '/admin/approved-posts' ? {color: '#ffffff', backgroundColor: props.website_color} : {color: website_color} } >
              <span className='nav_link_icon' style={pathname == '/admin/approved-posts' ? {color: '#ffffff'} : {fill: website_color} } >
                <svg xmlns="http://www.w3.org/2000/svg" width="23.89" height="23.89" viewBox="0 0 23.89 23.89">
                  <path id="Path_46" data-name="Path 46" d="M20.477,32a3.414,3.414,0,0,1,3.413,3.413V52.477a3.416,3.416,0,0,1-3.413,3.413H3.413A3.414,3.414,0,0,1,0,52.477V35.413A3.413,3.413,0,0,1,3.413,32ZM18.12,41.588a1.493,1.493,0,1,0-2.112-2.112l-5.77,5.77L7.882,42.889A1.493,1.493,0,0,0,5.77,45l3.413,3.413a1.5,1.5,0,0,0,2.112,0Z" transform="translate(0 -32)" />
                </svg>
              </span>
              <span className='nav_link_text'>Approved Posts</span>
            </CustomDiv>
          </NavLink>

          <NavLink className="nav_link"  to="categories">
            <CustomDiv className='nav_link_cntnt' style={pathname == '/admin/categories' ? {color: '#ffffff', backgroundColor: props.website_color} : {color: website_color} } >
              <span className='nav_link_icon' style={pathname == '/admin/categories' ? {color: '#ffffff'} : {fill: website_color} } >
                <svg xmlns="http://www.w3.org/2000/svg" width="23.888" height="20.902" viewBox="0 0 23.888 20.902">
                  <path id="Path_41" data-name="Path 41" d="M23.888,37.225V50.662A2.24,2.24,0,0,1,21.648,52.9H2.239A2.24,2.24,0,0,1,0,50.662V34.239A2.24,2.24,0,0,1,2.239,32H9.7l2.986,2.986h8.958A2.24,2.24,0,0,1,23.888,37.225Z" transform="translate(0 -32)" />
                </svg>
              </span>
              <span className='nav_link_text'>Categories</span>
            </CustomDiv>
          </NavLink>

          <NavLink className="nav_link"  to="feeds">
            <CustomDiv className='nav_link_cntnt' style={pathname == '/admin/feeds' ? {color: '#ffffff', backgroundColor: props.website_color} : {color: website_color} } >
              <span className='nav_link_icon' style={pathname == '/admin/feeds' ? {color: '#ffffff'} : {fill: website_color} } >
                <svg xmlns="http://www.w3.org/2000/svg" width="20.902" height="20.902" viewBox="0 0 20.902 20.902">
                  <path id="Path_42" data-name="Path 42" d="M1.193,38.723a1.117,1.117,0,1,0-.146,2.23,12.051,12.051,0,0,1,10.9,10.9,1.11,1.11,0,0,0,1.076,1.05l.074,0a1.119,1.119,0,0,0,1.045-1.19A14.235,14.235,0,0,0,1.193,38.723ZM1.493,32a1.493,1.493,0,0,0,0,2.986A16.441,16.441,0,0,1,17.916,51.409a1.493,1.493,0,1,0,2.986,0A19.43,19.43,0,0,0,1.493,32ZM2.944,46.925a2.989,2.989,0,0,0,0,5.977,3.015,3.015,0,0,0,2.99-2.986A2.965,2.965,0,0,0,2.944,46.925Z" transform="translate(0 -32)" />
                </svg>
              </span>
              <span className='nav_link_text'>Feeds</span>
            </CustomDiv>
          </NavLink>

          <NavLink className="nav_link"  to="promotions">
            <CustomDiv className='nav_link_cntnt' style={pathname == '/admin/promotions' ? {color: '#ffffff', backgroundColor: props.website_color} : {color: website_color} } >
              <span className='nav_link_icon' style={pathname == '/admin/promotions' ? {color: '#ffffff'} : {fill: website_color} } >
                <svg xmlns="http://www.w3.org/2000/svg" width="23.888" height="23.888" viewBox="0 0 23.888 23.888">
                  <path id="Path_43" data-name="Path 43" d="M22.395,8.379A3.066,3.066,0,0,1,23.888,11.2a3.066,3.066,0,0,1-1.493,2.818V20.9a1.491,1.491,0,0,1-2.547,1.054L17.808,19.88a11.9,11.9,0,0,0-8.445-3.457H8.958v5.972a1.491,1.491,0,0,1-1.493,1.493H4.479a1.492,1.492,0,0,1-1.493-1.493V16.423A2.987,2.987,0,0,1,0,13.437V8.958A2.987,2.987,0,0,1,2.986,5.972H9.364a11.946,11.946,0,0,0,8.445-3.5L19.847.437a1.493,1.493,0,0,1,2.547,1.056ZM9.364,8.958H8.958v4.479h.406a14.924,14.924,0,0,1,10.045,3.886V5.072A14.924,14.924,0,0,1,9.364,8.958Z" transform="translate(0 0)" />
                </svg>
              </span>
              <span className='nav_link_text'>Promotions</span>
            </CustomDiv>
          </NavLink>

          <NavLink className="nav_link"  to="static-pages">
            <CustomDiv className='nav_link_cntnt' style={staticPagePattern.test(pathname) ? {color: '#ffffff', backgroundColor: props.website_color} : {color: website_color} } >
              <span className='nav_link_icon' style={staticPagePattern.test(pathname) ? {color: '#ffffff'} : {fill: website_color} } >
                <svg xmlns="http://www.w3.org/2000/svg" width="17.916" height="23.888" viewBox="0 0 17.916 23.888">
                  <path id="Path_44" data-name="Path 44" d="M11.944,0V5.972h5.972ZM10.451,5.972V0H2.239A2.239,2.239,0,0,0,0,2.239V21.648a2.24,2.24,0,0,0,2.239,2.239H15.676a2.239,2.239,0,0,0,2.239-2.239V7.465h-5.93A1.5,1.5,0,0,1,10.451,5.972ZM12.69,19.409H5.225a.746.746,0,0,1,0-1.493H12.69a.746.746,0,0,1,0,1.493Zm0-2.986H5.225a.746.746,0,1,1,0-1.493H12.69a.746.746,0,0,1,0,1.493Zm.746-3.732a.749.749,0,0,1-.746.746H5.225a.746.746,0,1,1,0-1.493H12.69A.749.749,0,0,1,13.437,12.69Z" />
                </svg>
              </span>
              <span className='nav_link_text'>Static Pages</span>
            </CustomDiv>
          </NavLink>

          <NavLink className="nav_link"  to="users">
            <CustomDiv className='nav_link_cntnt' style={pathname == '/admin/users' ? {color: '#ffffff', backgroundColor: props.website_color} : {color: website_color} } >
              <span className='nav_link_icon' style={pathname == '/admin/users' ? {color: '#ffffff'} : {fill: website_color} } >
                <svg xmlns="http://www.w3.org/2000/svg" width="20.905" height="23.89" viewBox="0 0 20.905 23.89">
                  <path id="Path_47" data-name="Path 47" d="M10.452,11.946A5.973,5.973,0,1,0,4.48,5.973,5.973,5.973,0,0,0,10.452,11.946Zm2.366,2.24H8.087A8.088,8.088,0,0,0,0,22.272,1.618,1.618,0,0,0,1.617,23.89H19.289A1.614,1.614,0,0,0,20.9,22.272,8.087,8.087,0,0,0,12.818,14.186Z" />
                </svg>
              </span>
              <span className='nav_link_text'>Users</span>
            </CustomDiv>
          </NavLink>

          <NavLink className="nav_link"  to="settings">
            <CustomDiv className='nav_link_cntnt' style={pathname == '/admin/settings' ? {color: '#ffffff', backgroundColor: props.website_color} : {color: website_color} } >
              <span className='nav_link_icon' style={pathname == '/admin/settings' ? {color: '#ffffff'} : {fill: website_color} } >
                <svg xmlns="http://www.w3.org/2000/svg" width="22.517" height="23.89" viewBox="0 0 22.517 23.89">
                  <path id="Path_48" data-name="Path 48" d="M37.155,7.774a1.059,1.059,0,0,1-.294,1.148L34.84,10.76a8.893,8.893,0,0,1,0,2.37l2.02,1.838a1.059,1.059,0,0,1,.294,1.148,11.845,11.845,0,0,1-.733,1.6l-.219.378a12.294,12.294,0,0,1-1.031,1.46,1.067,1.067,0,0,1-1.143.317l-2.6-.831a10.209,10.209,0,0,1-2.053,1.19L28.793,22.9a1.119,1.119,0,0,1-.849.831,12.192,12.192,0,0,1-2.025.163,11.934,11.934,0,0,1-1.941-.163,1.119,1.119,0,0,1-.849-.831l-.583-2.664a9.171,9.171,0,0,1-2.053-1.19l-2.6.831a1.065,1.065,0,0,1-1.144-.317,12.244,12.244,0,0,1-1.032-1.46l-.218-.378a11.638,11.638,0,0,1-.736-1.6,1.06,1.06,0,0,1,.3-1.148L17.08,13.13a9.052,9.052,0,0,1,0-2.37L15.062,8.921a1.058,1.058,0,0,1-.3-1.148,11.693,11.693,0,0,1,.736-1.6l.218-.378a12.088,12.088,0,0,1,1.032-1.458A1.06,1.06,0,0,1,17.9,4.019l2.6.829A8.973,8.973,0,0,1,22.545,3.66L23.129,1a1.059,1.059,0,0,1,.849-.831,12.088,12.088,0,0,1,3.966,0A1.059,1.059,0,0,1,28.793,1l.583,2.664a9.964,9.964,0,0,1,2.053,1.188l2.6-.829a1.062,1.062,0,0,1,1.143.318A12.08,12.08,0,0,1,36.2,5.8l.219.378a11.845,11.845,0,0,1,.733,1.6Zm-11.194,7.9A3.754,3.754,0,1,0,22.228,11.9,3.741,3.741,0,0,0,25.961,15.678Z" transform="translate(-14.702)" />
                </svg>
              </span>
              <span className='nav_link_text'>Settings</span>
            </CustomDiv>
          </NavLink>

          <NavLink className="nav_link"  to="logout">
            <CustomDiv className='nav_link_cntnt' style={pathname == '/admin/logout' ? {color: '#ffffff', backgroundColor: props.website_color} : {color: website_color} } >
              <span className='nav_link_icon' style={pathname == '/admin/logout' ? {color: '#ffffff'} : {fill: website_color} } >
                <svg xmlns="http://www.w3.org/2000/svg" width="23.887" height="20.902" viewBox="0 0 23.887 20.902">
                  <path id="Path_45" data-name="Path 45" d="M7.465,49.916H4.479a1.493,1.493,0,0,1-1.493-1.493V36.479a1.493,1.493,0,0,1,1.493-1.493H7.465a1.493,1.493,0,1,0,0-2.986H4.479A4.479,4.479,0,0,0,0,36.479V48.423A4.479,4.479,0,0,0,4.479,52.9H7.465a1.493,1.493,0,1,0,0-2.986ZM23.449,41.4l-5.972-5.972a1.493,1.493,0,0,0-2.111,2.111l3.427,3.422H8.958a1.493,1.493,0,1,0,0,2.986h9.835l-3.424,3.424a1.493,1.493,0,1,0,2.111,2.111l5.972-5.972A1.494,1.494,0,0,0,23.449,41.4Z" transform="translate(0 -32)" />
                </svg>
              </span>
              <span className='nav_link_text'>Logout</span>
            </CustomDiv>
          </NavLink>
        </div>
      </div>
    </>
  );
}


export default SideMenuBarCtrl;