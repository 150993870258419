import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { GET } from '../../utils/axios.util';

const LogoutCtrl = () => {

    const navigate = useNavigate();

    useEffect(() => {
        let payload = {
            url : `${process.env.REACT_APP_SERVER}/admin/logout`
        }
        GET(payload).then(res => {
            if(res.status == 200) { 
                localStorage.removeItem('user')
                navigate("/admin/login")
            }
        }).catch(err => {
            console.log(err)
        })
    
    },[]);
}

export default LogoutCtrl;