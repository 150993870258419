import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactPaginate from 'react-paginate';

import { GET, POST, PATCH } from '../../../utils/axios.util';
import Loader from '../../common/loader';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CategoriesPageCtrl = (props) => {

  const { register, handleSubmit, setError, setValue, formState: { errors } } = useForm();
  const [ categories, setCategories ] = useState([])
  const [ categoryModal, setCategoryModal ] = useState(false)
  const [ modalCategoryId, setModalCategoryId ] = useState('')
  const [ formType, setFormType ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isNoRecords, setIsNoRecords ] = useState(false)
  const [ feeds, setFeeds ] = useState([])
  const [ posts, setPosts ] = useState([])

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 25
  const [ currentItems, setCurrentItems ] = useState([])
  const [ pageCount, setPageCount ] = useState(0);

  useEffect(() => {
    if(!(categories.length > 0)) {
      getCategories()
    }
  },[currentItems, categories, itemOffset, itemsPerPage]);

  const getCategories = () => {

    setIsLoading(true)
    let payload = {
      url : `${process.env.REACT_APP_SERVER}/admin/categories`
    }
    GET(payload).then(res => {
      if(res.data) { 
        const { data } = res.data

        const pageCount = Math.ceil(data.categories.length / itemsPerPage);
        const endOffset = itemOffset + itemsPerPage;
        let chunk = data.categories.slice(itemOffset, endOffset)
        setIsNoRecords(false)
        setIsLoading(false)
        setCategories(data.categories)
        setFeeds(data.feeds)
        setPosts(data.posts)
        setPageCount(pageCount)
        setCurrentItems(chunk)
      }
    }).catch(err => {
      setIsLoading(false)
      if(err.response.status === 404) {
        setIsNoRecords(true)
      }
    })
  }

  const setupModal = (event) => {
    let catId = event.currentTarget.getAttribute('data-id')
    let buttonType = event.currentTarget.getAttribute('data-type')
    
    if(buttonType === 'create') {
      setValue('position', '');
      setValue('category_name', '');
      setValue('is_highlighted', 0);
    }else {
      let filterCategory = categories.filter(cat => {
        return cat.id == catId
      })
      setValue('position', filterCategory[0]['position']);
      setValue('category_name', filterCategory[0]['name']);
      if(filterCategory[0]['is_highlighted']) {
        setValue('is_highlighted', 1);
      }else {
        setValue('is_highlighted', 0);
      }
    }
    // setError({})
    setModalCategoryId(catId)
    setFormType(buttonType)
    setCategoryModal(true)
  }

  const onSubmit = (data) => {

    var REQUEST;
    var slug;
    if(formType === 'create') {
      REQUEST = POST
      slug = 'create'
    }else {
      data['category_id'] = modalCategoryId
      REQUEST = PATCH
      slug = 'edit'
    }

    let payload = {
        url : `${process.env.REACT_APP_SERVER}/admin/categories/${slug}`,
        data
    }
    
    REQUEST(payload).then(res => {
      toast.success(res.data.message)
      getCategories()
      setCategoryModal(false)

    }).catch(err => {
        if(err) {
            const { data } = err.response.data
            let serverErrors = data

            serverErrors.forEach(errItem => {
                setError(errItem.field, {
                    type: "server",
                    message: errItem.message,
                });
            });
        }
    })
    
  }

  const deleteCategory = (catgryId) => {

    var catDelData =  {
      category_id: catgryId
    }

    let payload = {
      url : `${process.env.REACT_APP_SERVER}/admin/categories/delete`,
      data: catDelData
    }

    POST(payload).then(res => {
      toast.success(res.data.message)
      getCategories()

    }).catch(err => {
      if(err.response.status === 403) {
        toast.error(err.response.data.message)
      }
    })

  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % categories.length;
    
    const endOffset = newOffset + itemsPerPage;
    let chunk = categories.slice(newOffset, endOffset)
    const pageCount = Math.ceil(categories.length / itemsPerPage);
    setPageCount(pageCount)
    setCurrentItems(chunk)
    setItemOffset(newOffset);
  };

    return(
        <>
        <section className="admin_page_body">
          <div className="text-center">
            <h1 className="ad_page_hdng" style={{color: props.website_color}} >Categories</h1>
          </div>
          <div>
            <Button variant="danger" size="md" className="m-2" data-type="create" onClick={setupModal} style={{backgroundColor: props.website_color}} >CREATE NEW CATEGORY</Button>
          </div>
          <div className="m-2">  
            { isLoading && 
              <Loader />
            }   
            <Table responsive>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Position</th>
                  <th>Highlighted</th>
                  <th>Feeds</th>
                  <th colSpan={2}>Posts</th>
                </tr>
              </thead>
              <tbody>
              { (isNoRecords == false) && !isLoading && currentItems.length > 0 &&

                currentItems.map((elem, index) => {

                  let filteredFeed = feeds.filter(feed => { return feed.category_id == elem.id })
                  let filteredPost = posts.filter(post => { return post.category_id == elem.id })
                  
                  return (
                  <tr key={elem.id}>
                    <td>{elem.name}</td>
                    <td>{elem.position}</td>
                    <td>{elem.is_highlighted ? 'true' : 'false'}</td>
                    <td>{ filteredFeed.length > 0 ? filteredFeed[0]['feeds_count'] : 0 }</td>
                    <td>{ filteredPost.length > 0 ? filteredPost[0]['posts_count'] : 0 }</td>
                    <td width={100}>
                      <div className="d-grid gap-2">
                        <Button variant="danger" size="sm" data-id={elem.id} data-type="edit" onClick={setupModal} style={{backgroundColor: props.website_color}} >EDIT</Button>
                        <Button variant="danger" size="sm" onClick={() => deleteCategory(elem.id)} style={{backgroundColor: props.website_color}} >DELETE</Button>
                      </div>
                    </td>
                  </tr>
                  )
                })
              }
              { isNoRecords && 
                <tr className="text-center">
                  <td colSpan="6" className="fs-1">
                    No Records Found!
                  </td>
                </tr>
              }
              </tbody>
            </Table>

            { (isNoRecords == false) && !isLoading && currentItems.length > 0 &&
                <ReactPaginate 
                  className="justify-content-center mt-4 pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  activeClassName="active"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  breakLabel="..."
                  nextLabel={currentItems[currentItems.length-1]['id'] === categories[categories.length-1]['id'] ? null : 'Next'}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel={currentItems[0]['id'] === categories[0]['id'] ? null : 'Previous'}
                  renderOnZeroPageCount={null}
                />
            }

          </div>
        </section>

        <Modal show={categoryModal} aria-labelledby="Category" centered keyboard={false} onHide={() => setCategoryModal(false)} style={{ fontFamily: props.font_family }} >
                <Modal.Header>
                <Modal.Title className='text_soft_red mx-auto' style={{color: props.website_color}} >Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} >
                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="" {...register("category_name", { required: true })} name="category_name" defaultValue={''} onChange={(event) => setValue('category_name', event.target.value)} />

                            {errors.category_name?.type === 'required' && <p className="error">Category name is required</p>}
                            {errors.category_name?.type === 'server' && <p className="error">{errors.category_name.message}</p>}

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>Position</Form.Label>
                            <Form.Control type="number" placeholder="" {...register("position", { required: true })} name="position" defaultValue={''} onChange={(event) => setValue('position', event.target.value)} />
                            {errors.position?.type === 'required' && <p className="error">Position is required</p>}

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>Highlighted</Form.Label>
                            <Form.Select id="" {...register("is_highlighted", { required: true })} name="is_highlighted" defaultValue={0} onChange={(event) => setValue('is_highlighted', event.target.value)}>
                              <option value={1}>True</option>
                              <option value={0}>False</option>
                            </Form.Select>

                            {errors.category?.type === 'required' && <p className="error">This field is required</p>}
                            
                        </Form.Group>
                        <div className='d-grid'>
                            <Button variant="danger" size="lg" type="submit" style={{backgroundColor: props.website_color}} >SUBMIT</Button>
                        </div>
                    </Form>
                </Modal.Body>
        </Modal>
        </>
    )
}

export default CategoriesPageCtrl;