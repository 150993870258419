import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

function FooterCtrl(props){
    return(
        <>
        <div className="text-center">
            <a href="/">
                <img src={props.logo} />
            </a>
            <div className="d-flex justify-content-center" >
                <a target="_blank" className="social-icon" href="https://www.facebook.com/atlantatechblogs/">
                    <FontAwesomeIcon icon={icon({name: 'facebook-f', style: 'brands'})} />
                </a>
                <a target="_blank" className="social-icon" href="https://twitter.com/atltechblogs">
                    <FontAwesomeIcon icon={icon({name: 'twitter', style: 'brands'})} />
                </a>
                <a target="_blank" className="social-icon" href="https://www.linkedin.com/company/3671800">
                    <FontAwesomeIcon icon={icon({name: 'linkedin-in', style: 'brands'})} />
                </a>
            </div>
            <p>Copyright © 2022 Atlanta Tech Blogs, LLC. All Rights Reserved.</p>
        </div>
        </>
    )
}

export default FooterCtrl