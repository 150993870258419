import React, { useEffect, useState } from "react";
import {Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { connect, useDispatch } from "react-redux";
import { search } from "../../redux/actions";

function HeaderSearchCtrl(props){
    
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [ options, setOptions ] = useState(null)

    const [ categories, setCategories ] = useState(props.categories)

    useEffect(() => {

        setValue('keyword', props.keyword)
        setValue('category', props.category)
        setCategories(props.categories)

        if(categories.length > 0 && !options) {
            let optionValues =  categories.map((e)=>{
                return (
                    <option key={e.id} value={e.id} >{e.name}</option>
                )
            }) 
            setOptions(optionValues)
        } 
    },[ options, props.categories, props.keyword, props.category ]);

    const onSubmit = (data) => {
        dispatch(search(data.keyword, data.category, props.author))
        navigate('/')
    }

    return(
    <>
    <div {...props}>
        <Container>
            <Form className="" onSubmit={handleSubmit(onSubmit)} >
                <Row>
                    <Col sm={6} className="mt-1">
                        <Form.Group controlId="">
                            <Form.Label>KEYWORD(S)</Form.Label>
                            <Form.Control type="text" placeholder="Enter search query here" defaultValue={""} name="keyword" {...register("keyword")} onChange={(event) => setValue('keyword', event.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="">
                            <Form.Label>CATEGORY</Form.Label>
                            <Row>
                                <Col sm={7} className="mt-1">
                                    <Form.Select name="category" defaultValue={""} {...register("category")} onChange={(event) => setValue('category', event.target.value)} >
                                        <option value="">-- Category --</option>
                                        {options}
                                    </Form.Select>
                                </Col>
                                <Col sm={5} className="mt-1 d-grid">
                                    <Button className="" variant="danger" size="md" type="submit" style={{backgroundColor: props.website_color}} >SEARCH</Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </Container>
    </div>
    </>
    )
}

const mapStateToProps = state => {
    return {
       keyword: state.search.keyword,
       category: state.search.category,
       author: state.search.author,
       ...state.categories
    }
  }
  
export default connect(mapStateToProps, null)(HeaderSearchCtrl);