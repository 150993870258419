import React, { useState } from 'react';
import {Button, Col, Form, Row } from "react-bootstrap";

import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";

import { POST } from '../../../utils/axios.util';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function StaticCreatePageCtrl (props){

    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
    )
    const [content, setContent] = useState('')
    const [ contentState, setContentState ] = useState('')
    const [ isHtmlEditor, setIsHtmlEditor ] = useState(false)

    const onEditorStateChange = (editorState) => {
        setEditorState(() => editorState)
        let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        setContent(html)
    };

    const onChangeEditorMode = () => {
        setIsHtmlEditor(!isHtmlEditor)
    }

    const submitCreate = (data) => {
        
        data['content'] = isHtmlEditor ? contentState['blocks'][0]['text'] : content 
        data['editor_type'] = isHtmlEditor ? 'html' : 'text'

        let payload = {
            url : `${process.env.REACT_APP_SERVER}/admin/static-pages/create`,
            data
        }
        POST(payload).then(res => {
            toast.success(res.data.message)
            navigate("/admin/static-pages") 
        }).catch(err => {
            if(err) {
                const { data } = err.response.data
                let serverErrors = data
                serverErrors.forEach(errItem => {
                    setError(errItem.field, {
                        type: "server",
                        message: errItem.message,
                    });
                });
            }
        })
    }

    return(
        <>
          <section className="admin_page_body">
            <div className="text-center">
              <h1 className="ad_page_hdng" style={{color: props.website_color}} >Create Page </h1>
            </div>

            <Form onSubmit={handleSubmit(submitCreate)} >
                <div>
                    <Button variant="danger" size="md" className="m-2" type="submit" style={{backgroundColor: props.website_color}} >CREATE PAGE</Button>
                </div>
                <div className="m-2">
                    <Row>
                    <Col sm="7">
                        <Form.Group className="mb-3" controlId="">
                        <Form.Label>PAGE TITLE</Form.Label>
                        <Form.Control id="" name="title" defaultValue={''} {...register("title", 
                                { required: "Title is required" }
                            )} />
                            {errors.title ?.message && <p className="error">{errors.title ?.message}</p> }
                        </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col sm="7">
                        <Form.Group className="mb-3" controlId="">
                        <Form.Label>PAGE SLUG</Form.Label>
                        <Form.Control id="" name="slug" defaultValue={''} {...register("slug", 
                            { required: "Slug is required" }
                            )} />
                            {errors.slug ?.message && <p className="error">{errors.slug ?.message}</p> }
                        </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg="3">
                        <Form.Group className="mb-3" controlId="">
                        <Form.Label>PAGE STATUS</Form.Label>
                        <Form.Select className="text-center" name="status" defaultValue={'published'} {...register("status")} >
                            <option value="published">Published</option>
                            <option value="draft">Draft</option>
                        </Form.Select>
                        </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg="12">
                        
                        <Form.Group className="mb-3" controlId="">
                            <div className="d-flex justify-content-between">
                                <Form.Label>PAGE CONTENT</Form.Label>
                                { isHtmlEditor &&
                                    <span className="d-flex border border-secondary rounded align-items-center px-1 fs-17 mb-1" style={{ color: '#707070', cursor: 'pointer' }} onClick={onChangeEditorMode} >
                                        Text Editor
                                    </span>
                                }
                                { !isHtmlEditor &&
                                    <span className="d-flex border border-secondary rounded align-items-center px-1 fs-17 mb-1" style={{ color: '#707070', cursor: 'pointer' }} onClick={onChangeEditorMode} >
                                        HTML Editor
                                    </span>
                                }
                            </div>
                            { isHtmlEditor &&
                                <Editor 
                                defaultContentState={contentState} 
                                onContentStateChange={setContentState} wrapperClassName="wrapper-class"
                                editorClassName="editor-class" 
                                toolbarClassName="toolbar-class"
                                toolbarHidden
                                />
                            }
                            { !isHtmlEditor &&
                                <Editor 
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange} wrapperClassName="wrapper-class" 
                                editorClassName="editor-class" 
                                toolbarClassName="toolbar-class"
                                />
                            }
                        </Form.Group>
                    </Col>
                    </Row>
                </div>
            </Form>
          </section>
        </>
    )
}

export default StaticCreatePageCtrl;