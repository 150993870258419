import React, { useEffect, useState } from "react";
import {Button, Form, Table } from "react-bootstrap";
import moment from 'moment-timezone';
import ReactPaginate from 'react-paginate';

import { useNavigate } from "react-router-dom";
import { GET, POST } from '../../../utils/axios.util';
import Loader from '../../common/loader';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StaticPageCtrl = (props) => {

  const [ pages, setPages ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isNoRecords, setIsNoRecords ] = useState(false)
  const [ checkedState, setCheckedState ] = useState({})
  const [ allCheckedState, setAllCheckedState ] = useState(false)

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 25
  const [ currentItems, setCurrentItems ] = useState([])
  const [ pageCount, setPageCount ] = useState(0);

  let navigate = useNavigate();

  useEffect(() => {
    if(!(pages.length > 0)) {
      getPages()
    }

  },[currentItems, checkedState, itemOffset, itemsPerPage]);

  const getPages = () => {

    setIsLoading(true)
    let payload = {
      url : `${process.env.REACT_APP_SERVER}/admin/static-pages`
    }
    GET(payload).then(res => {
      if(res.data) { 
        const { data } = res.data
        const pageCount = Math.ceil(data.length / itemsPerPage);
        const endOffset = itemOffset + itemsPerPage;
        let chunk = data.slice(itemOffset, endOffset)
        let checkMap = {}

        for(let x of chunk) {
          checkMap[x.id] = false
        }
        setCheckedState(prevState => {
          return {
            ...prevState,
            ...checkMap
          }
        })
        setIsNoRecords(false)
        setIsLoading(false)
        setPages(data)
        setPageCount(pageCount)
        setCurrentItems(chunk)
      }
    }).catch(err => {
      setIsLoading(false)
      if(err.response.status === 404) {
        setIsNoRecords(true)
      }
    })
  }

  const setEditPage = (event) => {
    let pageId = parseInt(event.currentTarget.getAttribute('data-id'))
    navigate(`/admin/static-pages/${pageId}/edit`)
  }

  const handleAllCheckBox = (event) => {

    let inverseValue =  !allCheckedState

    let tempCheckstate = {}
    for(let y in checkedState) {
      if(inverseValue == true) {
        tempCheckstate[y] = true
      }else{
        tempCheckstate[y] = false
      }
      
    }
    
    setAllCheckedState(inverseValue)
    setCheckedState(prevState => {
      return {
        ...prevState,
        ...tempCheckstate
      }
    });
    
  }

  const handleSingleCheckBox = (checkId) => {

    let tempCheckstate = {}
    let tempCheckstateArr = []
    for(let y in checkedState) {
      if(y == checkId) {
        tempCheckstate[y] = !checkedState[y]
      }
    }
    let dummyCheck = {
      ...checkedState,
      ...tempCheckstate
    }
    for(let z in dummyCheck) {
      tempCheckstateArr.push(dummyCheck[z])
    }
    if(tempCheckstateArr.every(element => element === true)) {
      setAllCheckedState(true)
    }else {
      setAllCheckedState(false)
    }
    
    setCheckedState(prevState => {
      return {
        ...prevState,
        ...tempCheckstate
      }
    });
  }

  const submitChecked = () => {
    
    if(Object.keys(checkedState).length > 0) {

      var checkedData = Object.keys(checkedState).filter(key => checkedState[key] === true)

      if(checkedData.length > 0) {

        var data = { page_ids: JSON.stringify(checkedData) }

        let payload = {
          url : `${process.env.REACT_APP_SERVER}/admin/static-pages/delete`,
          data
        }

        POST(payload).then(res => {
          toast.success(res.data.message)
          getPages()

        }).catch(err => {
            console.log(err)
        })
      }
    }

  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % pages.length;
    
    const endOffset = newOffset + itemsPerPage;
    let chunk = pages.slice(newOffset, endOffset)
    const pageCount = Math.ceil(pages.length / itemsPerPage);
    let checkMap = {}
    for(let x of chunk) {
      checkMap[x.id] = false
    }
    setCheckedState(prevState => {
      return {
        ...checkMap
      }
    })
    setAllCheckedState(false)
    setPageCount(pageCount)
    setCurrentItems(chunk)
    setItemOffset(newOffset);
  };

    return(
        <>
          <section className="admin_page_body">
            <div className="text-center">
              <h1 className="ad_page_hdng" style={{color: props.website_color}} >Static Pages</h1>
            </div>
            <div>
              <Button variant="danger" size="md" className="m-2" onClick={() => navigate('/admin/static-pages/create')} style={{backgroundColor: props.website_color}} >CREATE NEW PAGE</Button>
              <Button variant="danger" size="md" className="m-2" onClick={submitChecked}  style={{backgroundColor: props.website_color}} >DELETE CHECKED</Button>
            </div>
            <div className="m-2">
              { isLoading && 
                <Loader />
              } 
            <Form>
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      {['checkbox'].map((type) => (
                        <div key={`inline-${type}`}>
                          <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={handleAllCheckBox} checked={allCheckedState} />
                        </div>
                      ))}
                    </th>
                    <th>Page Title</th>
                    <th>Page Status</th>
                    <th>Date Created</th>
                    <th colSpan={2}>Date Modified</th>
                  </tr>
                </thead>

                <tbody>
                  { (isNoRecords == false) && !isLoading && currentItems.length > 0 &&

                      currentItems.map((elem, index) => {

                        return (
                          <tr key={elem.id}>
                            <td>
                              {['checkbox'].map((type) => (
                                <div key={`inline-${type}`}>
                                  <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                                </div>
                              ))}
                            </td>
                            <td>{elem.title}</td>
                            <td>{elem.status.charAt(0).toUpperCase() + elem.status.slice(1)}</td>
                            <td>{moment(elem.created_at).tz(process.env.REACT_APP_TIME_ZONE).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td>{moment(elem.updated_at).tz(process.env.REACT_APP_TIME_ZONE).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td width={100}>
                            <div className="d-grid gap-2">
                              <Button variant="danger" size="sm" data-id={elem.id} onClick={setEditPage} style={{backgroundColor: props.website_color}} >EDIT</Button>
                              {/* <Button variant="danger" size="sm">DELETE</Button> */}
                            </div>
                            </td>
                          </tr>
                        )
                      })
                  }
                  { isNoRecords && 
                    <tr className="text-center">
                      <td colSpan="6" className="fs-1">
                        No Records Found!
                      </td>
                    </tr>
                  }
                </tbody>
              </Table>
            </Form>

            { (isNoRecords == false) && !isLoading && currentItems.length > 0 &&
                <ReactPaginate 
                  className="justify-content-center mt-4 pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  activeClassName="active"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  breakLabel="..."
                  nextLabel={currentItems[currentItems.length-1]['id'] === pages[pages.length-1]['id'] ? null : 'Next'}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel={currentItems[0]['id'] === pages[0]['id'] ? null : 'Previous'}
                  renderOnZeroPageCount={null}
                />
            }

            </div>
          </section>
        </>
    )
}

export default StaticPageCtrl;