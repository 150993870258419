import React, { useEffect, useState } from "react";
import {Button, Col, Form, Row } from "react-bootstrap";

import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState , ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useForm } from "react-hook-form";

import { useNavigate, useParams } from "react-router-dom";

import { GET, PATCH, POST } from '../../../utils/axios.util';
import Loader from '../../common/loader';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StaticEditPageCtrl = (props) => {

  const { register, handleSubmit, setError, reset, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const params = useParams();
  const [ page, setPage ] = useState({})
  const [ contentState, setContentState ] = useState('') // ContentState JSON
  const [content, setContent] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isNoRecords, setIsNoRecords ] = useState(false)
  const [ isHtmlEditor, setIsHtmlEditor ] = useState(false)

  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(
      ContentState.createFromText('')
    )
  );
  
  useEffect(() => {
    if(Object.keys(page).length === 0) {
      getPage()
    }
    
  },[page]);
  
  const getPage = () => {
      
    setIsLoading(true)
    let payload = {
      url : `${process.env.REACT_APP_SERVER}/admin/static-pages/${params.id}/edit`
    }
    GET(payload).then(res => {
        if(res.data) { 
          const { data } = res.data
          setIsLoading(false)
          setIsNoRecords(false)
          if(data.editor_type === 'text') {
            const contentBlock = htmlToDraft(data.content);
            if (contentBlock) {
              let newContentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
              let newEditorState = EditorState.createWithContent(newContentState);
              setEditorState(() => newEditorState)
              setContent(data.content)
              setIsHtmlEditor(false)
            }
          }else {
            let _contentState = ContentState.createFromText(data.content)
            let raw = convertToRaw(_contentState)
            setContentState(raw)
            setIsHtmlEditor(true)
          }
          setPage(data)
        }
    }).catch(err => {
      setIsLoading(false)
      if(err.response.status === 404) {
        setIsNoRecords(true)
      }
    })

  }

  const onEditorStateChange = (editorState) => {
    setEditorState(() => editorState)
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    setContent(html)
  };

  const onChangeEditorMode = () => {
    setIsHtmlEditor(!isHtmlEditor)
  }

  const submitEdit = (data) => {
    data['page_id'] = params.id
    data['content'] = isHtmlEditor ? contentState['blocks'][0]['text'] : content
    data['editor_type'] = isHtmlEditor ? 'html' : 'text'

    let payload = {
        url : `${process.env.REACT_APP_SERVER}/admin/static-pages/edit`,
        data
    }
    PATCH(payload).then(res => {
        toast.success(res.data.message)
        getPage()

    }).catch(err => {
        if(err) {
            const { data } = err.response.data
            let serverErrors = data
            serverErrors.forEach(errItem => {
                setError(errItem.field, {
                    type: "server",
                    message: errItem.message,
                });
            });
        }
    })
  }

  const deletePage = () => {

    let payload = {
      url : `${process.env.REACT_APP_SERVER}/admin/static-pages/${params.id}/delete`,
      data: params.id
    }
    
    POST(payload).then(res => {
        toast.success(res.data.message)
        navigate("/admin/static-pages") 
    }).catch(err => {
        if(err) {
            const { data } = err.response.data
            let serverErrors = data
            console.log('serverErrors')
            console.log(err)
            serverErrors.forEach(errItem => {
                setError(errItem.field, {
                    type: "server",
                    message: errItem.message,
                });
            });
        }
    })
  }

  return(
        <>
          <section className="admin_page_body">
            <div className="text-center">
              <h1 className="ad_page_hdng" style={{color: props.website_color}} >Edit Page</h1>
            </div>
            { isLoading && 
              <Loader />
            }
            { (isNoRecords == false) && !isLoading && Object.keys(page).length > 0 &&
              <Form onSubmit={handleSubmit(submitEdit)}>
                <div>
                  <Button variant="danger" size="md" className="m-2" type="submit" style={{backgroundColor: props.website_color}} >UPDATE PAGE</Button>
                  <Button variant="danger" size="md" className="m-2" onClick={deletePage} style={{backgroundColor: props.website_color}} >DELETE PAGE</Button>
                </div>
                <div className="m-2">
                  <Row>
                    <Col sm="7">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>PAGE TITLE</Form.Label>
                        <Form.Control id="" name="title" defaultValue={page.title} {...register("title", 
                          { required: "Title is required" }
                        )} />
                        {errors.title ?.message && <p className="error">{errors.title ?.message}</p> }
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="7">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>PAGE SLUG</Form.Label>
                        <Form.Control id="" name="slug" defaultValue={page.slug} {...register("slug", 
                          { required: "Slug is required" }
                        )} />
                        {errors.slug ?.message && <p className="error">{errors.slug ?.message}</p> }
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="3">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>PAGE STATUS</Form.Label>
                        <Form.Select className="text-center" name="status" defaultValue={page.status} {...register("status")} >
                          <option value="published">Published</option>
                          <option value="draft">Draft</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      
                      <Form.Group className="mb-3" controlId="">
                        <div className="d-flex justify-content-between">
                          <Form.Label>PAGE CONTENT</Form.Label>
                            { isHtmlEditor &&
                                <span className="d-flex border border-secondary rounded align-items-center px-1 fs-17 mb-1" style={{ color: '#707070', cursor: 'pointer' }} onClick={onChangeEditorMode} >
                                    Text Editor
                                </span>
                            }
                            { !isHtmlEditor &&
                                <span className="d-flex border border-secondary rounded align-items-center px-1 fs-17 mb-1" style={{ color: '#707070', cursor: 'pointer' }} onClick={onChangeEditorMode} >
                                    HTML Editor
                                </span>
                            }
                          </div>
                          { isHtmlEditor &&
                                <Editor
                                defaultContentState={contentState} 
                                onContentStateChange={setContentState} 
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class" 
                                toolbarClassName="toolbar-class"
                                toolbarHidden
                                />
                            }
                            { !isHtmlEditor &&
                                <Editor 
                                editorState={editorState} 
                                onEditorStateChange={onEditorStateChange} wrapperClassName="wrapper-class" 
                                editorClassName="editor-class" 
                                toolbarClassName="toolbar-class"
                                />
                            }
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Form>
              }
            { isNoRecords && 
              <p className="text-center">
                No Records Found!
              </p>
            }
          </section>
        </>
    )
}

export default StaticEditPageCtrl;