import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactPaginate from 'react-paginate';

import { GET, POST } from '../../../utils/axios.util';
import Loader from '../../common/loader';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PromotionsPageCtrl = (props) => {

  const { register, handleSubmit, setError, setValue, formState: { errors } } = useForm();
  const [ promotions, setPromotions ] = useState([])
  const [ promotionModal, setPromotionModal ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isNoRecords, setIsNoRecords ] = useState(false)
  const [ checkedState, setCheckedState ] = useState({})
  const [ allCheckedState, setAllCheckedState ] = useState(false)
  const [ formType, setFormType ] = useState('')
  const [ modalPromotionId, setModalPromotionId ] = useState('')
  const [ file, setFile ] = useState('')
  const [ previewImage, setPreviewImage] = useState('');

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 25
  const [ currentItems, setCurrentItems ] = useState([])
  const [ pageCount, setPageCount ] = useState(0);

  useEffect(() => {
    if(!(promotions.length > 0)) {
        getPromotions()
    }

  },[currentItems, checkedState, itemOffset, itemsPerPage]);

  const getPromotions = () => {

    setIsLoading(true)
    let payload = {
      url : `${process.env.REACT_APP_SERVER}/admin/promotions`
    }
    GET(payload).then(res => {
      if(res.data) { 
        const { data } = res.data
        const pageCount = Math.ceil(data.length / itemsPerPage);
        const endOffset = itemOffset + itemsPerPage;
        let chunk = data.slice(itemOffset, endOffset)
        let checkMap = {}

        for(let x of chunk) {
          checkMap[x.id] = false
        }
        setCheckedState(prevState => {
          return {
            ...prevState,
            ...checkMap
          }
        })
        setIsNoRecords(false)
        setIsLoading(false)
        setPromotions(data)
        setPageCount(pageCount)
        setCurrentItems(chunk)
      }
    }).catch(err => {
      setIsLoading(false)
      if(err.response.status === 404) {
        setIsNoRecords(true)
      }
    })
  }

  const setupModal = (event) => {
    let promotionId = event.currentTarget.getAttribute('data-id')
    let buttonType = event.currentTarget.getAttribute('data-type')
    
    if(buttonType === 'create') {
      setValue('url', '');
      setValue('priority', '');
      setFile('')
      setValue('image', '')
      setPreviewImage('');
    }else {
      let filterPromotion = promotions.filter(prom => {
        return prom.id == promotionId
      })

      setValue('url', filterPromotion[0]['url']);
      setValue('priority', filterPromotion[0]['priority']);
      setValue('image', filterPromotion[0]['image_originalname']);
      setPreviewImage(filterPromotion[0]['image']);
    }
    setModalPromotionId(promotionId)
    setFormType(buttonType)
    setPromotionModal(true)
  }

  const handleUploadChange = (event) => {

    if(event.target.files[0] &&  event.target.files[0] !== undefined && event.target.files[0] !== null){

      setFile(event.target.files[0])
      setValue('image', event.target.files[0]['name'])
      setPreviewImage(URL.createObjectURL(event.target.files[0]));
    }
  }

  const handleAllCheckBox = (event) => {

    let inverseValue =  !allCheckedState

    let tempCheckstate = {}
    for(let y in checkedState) {
      if(inverseValue == true) {
        tempCheckstate[y] = true
      }else{
        tempCheckstate[y] = false
      }
      
    }
    
    setAllCheckedState(inverseValue)
    setCheckedState(prevState => {
      return {
        ...prevState,
        ...tempCheckstate
      }
    });
    
  }

  const handleSingleCheckBox = (checkId) => {

    let tempCheckstate = {}
    let tempCheckstateArr = []
    for(let y in checkedState) {
      if(y == checkId) {
        tempCheckstate[y] = !checkedState[y]
      }
    }
    let dummyCheck = {
      ...checkedState,
      ...tempCheckstate
    }
    for(let z in dummyCheck) {
      tempCheckstateArr.push(dummyCheck[z])
    }
    if(tempCheckstateArr.every(element => element === true)) {
      setAllCheckedState(true)
    }else {
      setAllCheckedState(false)
    }
    
    setCheckedState(prevState => {
      return {
        ...prevState,
        ...tempCheckstate
      }
    });
  }

  const submitChecked = () => {

    if(Object.keys(checkedState).length > 0) {

      var checkedData = Object.keys(checkedState).filter(key => checkedState[key] === true)

      if(checkedData.length > 0) {

        var data = { promotion_ids: JSON.stringify(checkedData) }

        let payload = {
          url : `${process.env.REACT_APP_SERVER}/admin/promotions/delete`,
          data
        }

        POST(payload).then(res => {
          toast.success(res.data.message)
          getPromotions()

        }).catch(err => {
            if(err) {
                const { data } = err.response.data
                let serverErrors = data

                serverErrors.forEach(errItem => {
                    setError(errItem.field, {
                        type: "server",
                        message: errItem.message,
                    });
                });
            }
        })
      }
    }

  }

  const submitForm = (data) => {
    const formData = new FormData();
    formData.append("promotion", file);
    formData.append("url", data.url);
    formData.append("priority", data.priority);

    var slug;
    if(formType === 'create') {
      slug = 'create'
    }else {
      formData.append("promotion_id", modalPromotionId);
      slug = 'edit'
    }

    let payload = {
      url : `${process.env.REACT_APP_SERVER}/admin/promotions/${slug}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    }

    POST(payload).then(res => {
      toast.success(res.data.message)
      setPromotionModal(false)
      getPromotions()

    }).catch(err => {
        if(err) {
            const { data } = err.response.data
            let serverErrors = data

            serverErrors.forEach(errItem => {
                setError(errItem.field, {
                    type: "server",
                    message: errItem.message,
                });
            });
        }
    })
  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % promotions.length;
    
    const endOffset = newOffset + itemsPerPage;
    let chunk = promotions.slice(newOffset, endOffset)
    const pageCount = Math.ceil(promotions.length / itemsPerPage);
    let checkMap = {}
    for(let x of chunk) {
      checkMap[x.id] = false
    }
    setCheckedState(prevState => {
      return {
        ...checkMap
      }
    })
    setAllCheckedState(false)
    setPageCount(pageCount)
    setCurrentItems(chunk)
    setItemOffset(newOffset);
  };

    return(
        <>
        <section className="admin_page_body">
          <div className="text-center">
            <h1 className="ad_page_hdng" style={{color: props.website_color}} >Promotions</h1>
          </div>
          <div>
            <Button variant="danger" size="md" className="m-2" data-type="create" onClick={setupModal} style={{backgroundColor: props.website_color}} >CREATE PROMOTION</Button>
            <Button variant="danger" size="md" className="m-2" onClick={submitChecked}  style={{backgroundColor: props.website_color}} >DELETE CHECKED</Button>
          </div>
          <div className="m-2">
            { isLoading && 
              <Loader />
            } 
          <Form>

            <Table responsive>
              <thead>
                <tr>
                  <th>
                    {['checkbox'].map((type) => (
                      <div key={`inline-${type}`}>
                        <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={handleAllCheckBox} checked={allCheckedState} />
                      </div>
                    ))}
                  </th>
                  <th width="130">Image</th>
                  <th>Destination URL</th>
                  <th colSpan={2}>Priority</th>
                </tr>
              </thead>

              <tbody>
              { (isNoRecords == false) && !isLoading && currentItems.length > 0 &&

                  currentItems.map((elem, index) => {

                    return (
                      <tr key={elem.id}>
                        <td>
                          {['checkbox'].map((type) => (
                            <div key={`inline-${type}`}>
                              <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                            </div>
                          ))}
                        </td>
                        <td>
                          {
                            elem.image && <img width="100%" src={elem.image} />
                          }
                        </td>
                        <td>{elem.url}</td>
                        <td>{elem.priority}</td>
                        <td width={100}>
                          <div className="d-grid gap-2">
                            <Button variant="danger" size="sm" data-id={elem.id} data-type="edit" onClick={setupModal} style={{backgroundColor: props.website_color}} >EDIT</Button>
                            {/* <Button variant="danger" size="sm">DELETE</Button> */}
                          </div>
                        </td>
                      </tr>
                    )
                  })
              }
              { isNoRecords && 
                <tr className="text-center">
                  <td colSpan="6" className="fs-1">
                    No Records Found!
                  </td>
                </tr>
              }
              </tbody>
            </Table>
          </Form>

          { (isNoRecords == false) && !isLoading && currentItems.length > 0 &&
                <ReactPaginate 
                  className="justify-content-center mt-4 pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  activeClassName="active"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  breakLabel="..."
                  nextLabel={currentItems[currentItems.length-1]['id'] === promotions[promotions.length-1]['id'] ? null : 'Next'}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel={currentItems[0]['id'] === promotions[0]['id'] ? null : 'Previous'}
                  renderOnZeroPageCount={null}
                />
            }

          </div>
        </section>


        <Modal show={promotionModal} aria-labelledby="Category" centered keyboard={false} onHide={() => setPromotionModal(false)} style={{ fontFamily: props.font_family }} >
          <Modal.Header>
          <Modal.Title className='text_soft_red mx-auto' style={{color: props.website_color}} >Promotion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form encType="multipart/form-data" onSubmit={handleSubmit(submitForm)} >
                  <input id="upload" name="upload" type="file" onChange={handleUploadChange}  hidden />

                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Image</Form.Label>
                    <Form.Control type="text" placeholder="[upload file here]" name="image" defaultValue={previewImage} onClick={() => document.getElementById("upload").click()} {...register("image", { required: true })} />
                    {errors.image ?.message && <p className="error">{errors.image ?.message}</p> }
                  </Form.Group>
                  { previewImage &&
                    <img width="100"src={previewImage} />
                  }

                  <Form.Group className="mb-3" controlId="">
                      <Form.Label>Destination URL</Form.Label>
                      <Form.Control type="text" placeholder="" {...register("url", {
                        required: "Destination URL is required",
                        pattern: {
                            value: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig,
                            message: "Invalid Destination URL"
                        }}
                        )} name="url" defaultValue={''} onChange={(event) => setValue('url', event.target.value)} />

                      {errors.url ?.message && <p className="error">{errors.url ?.message}</p> }

                  </Form.Group>

                  <Form.Group className="mb-3" controlId="">
                      <Form.Label>Priority</Form.Label>
                      <Form.Control type="number" placeholder="" {...register("priority", { required: true })} name="priority" defaultValue={''} onChange={(event) => setValue('priority', event.target.value)} />
                      {errors.priority?.type === 'required' && <p className="error">Priority is required</p>}

                  </Form.Group>

                  <div className='d-grid'>
                      <Button variant="danger" size="lg" type="submit" style={{backgroundColor: props.website_color}} >SUBMIT</Button>
                  </div>
              </Form>
          </Modal.Body>
        </Modal>

        </>
    )
}

export default PromotionsPageCtrl;