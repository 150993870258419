import axios from "axios";

const GET = async (payload) => {
    return new Promise(function (resolve, reject) {
        
        const {url, headers} = payload

        var headerFields = {
            'Content-Type': 'application/json',
            'Api-Key': process.env.REACT_APP_SERVER_API_KEY
        }
        if(payload.headers) {
            headerFields = {
                ...headerFields,
                ...JSON.stringify(payload.headers)
            }
        }
        axios.get(url, {
            headers: headerFields
        })      
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            return reject(err);
        })

    });
}

const POST = (payload)  => {
    return new Promise(function (resolve, reject) {
        
        const {url, data, headers} = payload

        var dataEncoded = '';
        dataEncoded = Object.keys(data)
        .map((key) => `${key}=${encodeURIComponent(data[key])}`)
        .join('&');

        var headerFields = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Api-Key': process.env.REACT_APP_SERVER_API_KEY
        }

        if(headers) {
            headerFields = {
                ...headerFields,
                ...headers
            }
            if(headers['Content-Type'] === 'multipart/form-data' ) {
                dataEncoded = data
            }
        }

        axios.post(url, dataEncoded, { headers: headerFields})   
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            return reject(err);
        })

    });
}

const PATCH = (payload)  => {
    return new Promise(function (resolve, reject) {
        
        const {url, data, headers} = payload

        var headerFields = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Api-Key': process.env.REACT_APP_SERVER_API_KEY
        }

        var dataEncoded = Object.keys(data)
        .map((key) => `${key}=${encodeURIComponent(data[key])}`)
        .join('&');

        axios.patch(url, dataEncoded, { headers: headerFields})   
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            return reject(err);
        })

    });
}

export { GET, POST, PATCH }