import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GET, POST } from '../../utils/axios.util';

const AddBlogModalCtrl = (props) => {

    const [ categories, setCategories ] = useState([])
    const [ options, setOptions ] = useState(null)
    const [ isDisabled, setIsDisabled ] = useState('')
    
    const { register, handleSubmit, setError, reset, formState: { errors } } = useForm();

    var textColor = {color: props.website_color}

    useEffect(() => {
       
        if(!(categories.length > 0)) {
            getCategories()
        }
        if(categories.length > 0 && !options) {
            let optionValues =  categories.map((e)=>{
                return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                )
            }) 
            setOptions(optionValues)
        }

    },[ categories, options ]);

    const getCategories = () => {
        let payload = {
            url : `${process.env.REACT_APP_SERVER}/list-categories`
        }

        GET(payload).then(res => {
            if(res.data) { 
                const { data } = res.data
                setCategories(data)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const onSubmit = (data) => {
        let payload = {
            url : `${process.env.REACT_APP_SERVER}/submit-blog`,
            data
        }

        setIsDisabled(true)
        
        POST(payload).then(res => {
       
            toast.success(res.data.message)
            setIsDisabled('')
            reset()
            props.onHide()

        }).catch(err => {
            if(err) {
                setIsDisabled('')

                const { data } = err.response.data
                let serverErrors = data
                serverErrors.forEach(errItem => {
                    setError(errItem.field, {
                        type: "server",
                        message: errItem.message,
                    });
                });
            }
        })
    }
        
    return(
        <>
            <Modal {...props} aria-labelledby="Add Your Blog" centered keyboard={false} style={{ fontFamily: props.font_family }} >
                <Modal.Header>
                    <Modal.Title className='text_soft_red mx-auto' style={textColor} >Add Your Blog</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} >
                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>Business Name</Form.Label>
                            <Form.Control type="text" placeholder="Business name goes here" {...register("blog_title", { 
                                required: 'Business name is required',
                                pattern: {
                                    value: /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/,
                                    message: "Only letters and numbers are allowed"
                                }
                            })} name="blog_title" />

                            {errors.blog_title && <p className="error">{errors.blog_title.message}</p>}

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>RSS FEED URL</Form.Label>
                            <Form.Control type="text" placeholder="" {...register("rss_url", {  
                                required: "RSS Feed URL is required",
                                pattern: {
                                    value: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig,
                                    message: "Invalid RSS URL"
                                }
                            })} name="rss_url" />

                            {errors.rss_url && <p className="error">{errors.rss_url.message}</p> }

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>EMAIL ADDRESS</Form.Label>
                            <Form.Control type="text" placeholder="" {...register("email", 
                                {
                                    required: "Email Address is required", 
                                    pattern: { 
                                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Invalid email address"
                                    }
                                }
                            )} name="email" />

                            {errors.email ?.message && <p className="error">{errors.email ?.message}</p> }

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>TWITTER HANDLE (NO @ SYMBOL)</Form.Label>
                            <Form.Control type="text" placeholder="" {...register("twitter", {
                                pattern: {
                                    value: /^[a-zA-Z0-9~!#$%^&*()_+=[\]{}|\\,.?: -]*$/,
                                    message: "Invalid twitter handle"
                                }
                            })} name="twitter" />

                            {errors.twitter ?.message && <p className="error">{errors.twitter ?.message}</p> }

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>CATEGORY</Form.Label>
                            <Form.Select id="" {...register("category", { required: true })} name="category" >
                                <option value="">-- Choose a Category --</option>
                                {options}
                            </Form.Select>

                            {errors.category?.type === 'required' && <p className="error">Category is required</p>}
                            
                        </Form.Group>
                        <div className='d-grid'>
                            <Button id="blogButton" variant="danger" size="lg" type="submit" style={{backgroundColor: props.website_color}} disabled={isDisabled} >SUBMIT</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            
      </>
    )
}

export default AddBlogModalCtrl;