const initialState = {
    keyword: '',
    category: '',
    author: ''
}

const searchReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'SEARCH':
            return {
                ...state,
                keyword: action.payload.keyword,
                category: action.payload.categoryId,
                author: action.payload.author
            }
        case 'CLEAR':
            return {
                ...state,
                keyword: '',
                category: '',
                author: ''
            }
        default: return state
    }
}

export default searchReducer